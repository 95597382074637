<template>
	<v-card
		outlined
		class="ma-3 d-flex flex-column cc_accommodation"
		width="300"
		v-if="!loading.accommodations"
	>
		<v-responsive height="200px">
			<vue-picture-swipe
				v-if="Array.isArray(accommodation_images) && accommodation_images.length"
				:options="{ history: false }"
				:items="accommodation_images"
				ref="pictureSwipe"
				class="cc_accommodation__picture-swipe"
			></vue-picture-swipe>
			<v-img v-else :src="accommodation.meta.thumb" height="100%" width="100%"></v-img>
		</v-responsive>

		<v-toolbar flat max-height="64">
			<v-toolbar-title
				@click="open_accommodation(accommodation)"
			>{{ get_accommodation_title(accommodation) }}</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-toolbar-items
				class="d-flex align-center"
				v-bind:class="{ cc_redIcon: exceeded_max_capacity() }"
			>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<icon name="users" class="mr-2 mt-1 cc_accommodation_persons" v-on="on" />
					</template>

					<span>{{ $t("maxCapacity") }}</span>
				</v-tooltip>

				<span>{{ accommodation.meta.persons_max }}</span>
			</v-toolbar-items>
		</v-toolbar>

		<v-divider class="mx-4"></v-divider>

		<v-card-text>
			<div class="cc_product_price cc_price_error" v-if="this.loader">
				<div class>{{ $t("loading") }}</div>
			</div>
			<!-- When wrong message is displayed,maybe bug in helpers.day_is_valid code, on Price and in Helpers -->
			<div
				class="cc_product_price cc_price_error"
				v-else-if="!$helpers.day_is_valid(reservation.arrival, accommodation.meta.arrival_days)"
			>
				<div class>{{ $t("correctArrivalMessage") }}</div>
			</div>

			<div
				class="cc_product_price cc_price_error"
				v-else-if="!$helpers.day_is_valid(reservation.departure, accommodation.meta.departure_days)"
			>
				<div class>{{ $t("correctDepartureMessage") }}</div>
			</div>

			<div class="cc_product_price cc_price_error" v-else-if="!duration_is_valid()">
				<div class>Maximale verblijf is {{ this.accommodation.meta.duration_max }} dagen!</div>
			</div>

			<div
				class="cc_product_price cc_price_error"
				v-else-if="!calculation.total || !dates_equal(reservation, calculation)"
			>
				<div class>Accommodatie is voor de aangegeven periode niet beschikbaar!</div>
			</div>

			<div class="cc_product_price" v-else>
				<div
					class="cc_product_price_date"
				>{{ price_date(reservation.arrival) }} / {{ price_date(reservation.departure) }}</div>
				<div class="cc_product_price_value">{{ $helpers.get_money(calculation.total, $store.state) }}</div>
			</div>
		</v-card-text>

		<v-divider class="mx-4"></v-divider>

		<v-card-text
			class="cc_accommodation__description"
		>{{ get_accommodation_description(accommodation) }}</v-card-text>

		<v-spacer />

		<v-divider />

		<v-card-actions class="d-flex justify-space-between ma-3">
			<v-btn
				large
				:color="$variables.colors.gray"
				width="47%"
				depressed
				@click="open_accommodation(accommodation)"
			>{{ $t("more") }}</v-btn>

			<transition name="fade">
				<v-btn
					large
					:color="$variables.colors.cyan"
					width="47%"
					depressed
					:disabled="!book_allowed"
					@click="select_accommodation(accommodation)"
					class="white--text"
				>{{ $t("book") }}</v-btn>
			</transition>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import VuePictureSwipe from "vue-picture-swipe";

//components
import icon from "@/api/icons.vue";
import Loader from "@/components/Loader.vue";

//mixins
import { Price } from "@/mixins/Price.js";
import { Translation } from "@/mixins/Translation.js";

export default {
	props: ["accommodation"],

	components: {
		VuePictureSwipe,
		icon,
		Loader
	},

	mixins: [Price, Translation],

	computed: {
		...mapState([
			"administration",
			"reservation",
			"settings",
			"accommodations",
			// "price_calculations",
			"loading"
		]),

		dialog_persons() {
			// return this.$store.state.dialog.persons;
			return this.$store.state.reservation.persons;
		},

		book_allowed() {
			return (
				this.reservation.persons <=
					this.accommodation.meta.persons_max &&
				this.foundPrice &&
				this.calculation.total &&
				this.$helpers.day_is_valid(
					this.reservation.arrival,
					this.accommodation.meta.arrival_days
				) &&
				this.$helpers.day_is_valid(
					this.reservation.departure,
					this.accommodation.meta.departure_days
				) &&
				this.dates_equal(this.reservation, this.calculation)
			);

			// if (
			// 	this.reservation.persons <=
			// 		this.accommodation.meta.persons_max &&
			// 	this.foundPrice &&
			// 	this.calculation.total
			// ) {
			// 	return true;
			// }

			// return false;
		},

        accommodation_images(){
            const images = this.accommodation.images.filter(image => {
                if(image.type_id === this.accommodation.id) return image;
            })

            return images;
        }
	},

	data() {
		return {
			// transition: "scale-transition",
			// price: null
		};
	},

	watch: {
		dialog_persons(newValue, oldValue) {
		// dialog_persons() {
            // if(newValue !== oldValue){
            if(this.check_selection()){
                this.loader = true;
                this.calculate_prices();
            }
            // }
			/**
			 * @Function used for recalculation price when amount of persons change
			 */
			// if (value === false) {
			// 	if (this.check_selection()) {
			// 		this.loader = true;
			// 		this.calculate_prices();
			// 	}
			// }
		}
	},

	methods: {
		price_date: function(datestring) {
			return this.$moment(datestring).format("DD-MM");
		},

		open_accommodation: function(selected_accommodation) {
			this.$store.commit("set_accommodation", selected_accommodation);

			this.$router.push({
				name: "accommodation",
				params: {
					accommodation_id: selected_accommodation.id
				}
			});
		},

		select_accommodation(selected_accommodation) {
			this.$store.commit("set_accommodation", selected_accommodation);

			this.$router.push({
				name: "book"
			});
		},

		exceeded_max_capacity() {
			if (
				this.reservation.persons > this.accommodation.meta.persons_max
			) {
				return true;
			}

			return false;
		}
	},

	created() {
		if (this.check_selection()) {
			this.loader = true;
			this.calculate_prices();
		}
	}
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.cc_persons_error {
	fill: red;
}

.cc_product_price {
	padding: 0;
	border: none;
}

.cc_picture_swipe {
	height: 200px;
	width: 100%;
	div {
		figure {
			margin: 0 !important;
		}
		img {
			height: 200px !important;
			width: 100% !important;
		}
	}
	figure {
		margin: 0 !important;
	}
}

.cc_accommodation__description {
	height: 87px;
	text-overflow: clip;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.cc_accommodation_persons svg {
	height: 20px;
	width: 20px;
}

.cc_redIcon svg path {
	fill: red;
}

.cc_redIcon {
	color: red;
}

.cc_price_error {
	color: red;
}
</style>
