import axios from "axios";
import helpers from "./helpers";
import moment from "moment";

class reservation {
    constructor(store) {
        this.store = store;
        this.state = store.state;
        this.endpoint = "reservations";
    }

    create(formData) {
        if (!formData) {
            throw new Error("No formdata present");
        }

        let birthDate = `${formData.birth.birth_year}-${formData.birth.birth_month}-${formData.birth.birth_day}`;

        let requestBody = new FormData();
        // requestBody.set("arrival", moment(this.state.reservation.arrival).format("YYYY-MM-DD")); //--> not needed
        // requestBody.set("departure", moment(this.state.reservation.departure).format("YYYY-MM-DD")); //--> not needed
        // requestBody.set("persons", this.state.reservation.persons);  //--> not needed

        requestBody.set("accommodation_id", this.state.accommodation.id);
        requestBody.set("calculation_id", this.state.price_calculation.calculation_id);
        requestBody.set("calculation_draft_id", this.state.price_calculation.calculation_draft_id);
        requestBody.set("channel_id", this.state.settings.channel_id);
        /**Form Data */
        requestBody.set("first_name", this.state.reservation.meta.first_name);
        requestBody.set("last_name", this.state.reservation.meta.last_name);
        requestBody.set("gender", this.state.reservation.meta.gender);
        requestBody.set("zipcode", this.state.reservation.meta.zipcode);
        requestBody.set("city", this.state.reservation.meta.city);
        requestBody.set("phone", this.state.reservation.meta.phone);
        requestBody.set("address", this.state.reservation.meta.address);
        requestBody.set("address_number", this.state.reservation.meta.address_number);
        requestBody.set("country", this.state.reservation.meta.country);
        requestBody.set("email", this.state.reservation.meta.email);
        requestBody.set("note_guest", this.state.reservation.meta.note_guest);
        requestBody.set("birthday", birthDate);

        /**Booking Data */
        requestBody.set("source", "online"); //--> temp
        requestBody.set("pay_method", "mollie"); //--> set when paymethod is added

        let params = "&admin_id=" + this.state.administration.id;

        return axios({
            method: "post",
            url: helpers.get_api_endpoint(this.state, this.endpoint, params),
            data: requestBody,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        // .then(response => {
        //     console.log(response, "reservation response");
        //     this.store.commit("set_finished", true);
        // })
        // .catch(error => {
        //     console.log(error, "reservation error");
        //     this.store.commit("set_finished", false);
        // });
    }
}

export default reservation;
