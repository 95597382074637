<template>
	<v-footer padless class="d-flex pa-4">
		<div class="payment_icons d-flex justify-start align-start">
			<v-tooltip top v-for="(icon, index) in payment_icons" :key="index">
				<template v-slot:activator="{ on }">
					<v-img :src="icon.icon" max-height="40" max-width="40" contain v-on="on"></v-img>
				</template>
				<span>{{icon.name}}</span>
			</v-tooltip>
		</div>
		<v-spacer></v-spacer>
		<div>
			{{ $t('Powerd by') }}
			<a href="https://www.camping.care" target="_blank">Camping.care</a>
		</div>
	</v-footer>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "cc_footer",

	computed: {
		...mapState(["payment_icons"])
	}
};
</script>

<style lang="scss" scoped>
//Maybe delete scss in external file --> not used styling
.payment_icons {
	width: 50%;
	overflow-x: hidden;
}
</style>
