//Used in Header and Filter
import languages from "@/assets/languages_2.json";

export const Translation = {
    computed: {
        available_translations() {
            let formatted_languages = [];
            this.administration.meta.languages.forEach((availableLanguage) => {
                languages.forEach((language) => {
                    if (availableLanguage === language.value) {
                        formatted_languages.push(language);
                    }
                });
            });

            return formatted_languages;
        },
    },

    data() {
        return {
            selected_language: this.$i18n.locale,
            default_park_language: this.$store.state.administration.meta.default_park_language,
        };
    },

    watch: {
        //Watches when selected_language property changes
        selected_language: function() {
            this.$store.commit("change_language", this.selected_language);

            this.$i18n.locale = this.selected_language;
        },
    },

    methods: {
        get_accommodation_title(accommodation) {
            if (!Array.isArray(accommodation.translations)) {
                if (accommodation.translations[this.$i18n.locale].name) {
                    return accommodation.translations[this.$i18n.locale].name;
                }

                if (accommodation.translations["en"].name) {
                    return accommodation.translations["en"].name;
                }
            }

            return accommodation.name;
        },

        get_accommodation_description(accommodation) {
            if (!Array.isArray(accommodation.translations)) {
                if (accommodation.translations[this.$i18n.locale].description) {
                    return accommodation.translations[this.$i18n.locale].description;
                }

                if (accommodation.translations["en"].description) {
                    return accommodation.translations["en"].description;
                }
            }

            return accommodation.description;
        },
    },
};
