<template>
	<div class="list_mobile">
		<div v-if="!loading.accommodations" class="cc_page">
			<div class="cc_list_search">
				<Search />
			</div>

			<div class="acco_list">
				<div class="cc_products" v-if="!loading.accommodations">
					<Accommodation
						v-for="accommodation in filtered_accommodations ? filtered_accommodations : accommodations"
						:key="accommodation.id"
						:accommodation="accommodation"
					/>
				</div>
			</div>
		</div>

		<Loader v-else />
	</div>
</template>

<script>
import { mapState } from "vuex";
import class_accommodations from "@/api/accommodations/class.accommodations";

//components
import Search from "@/components/Search";
import Loader from "@/components/Loader.vue";
import Accommodation from "@/components/Accommodation.vue";

export default {
	components: {
		Search,
		Loader,
		Accommodation
	},

	computed: {
		...mapState([
			"administration",
			"accommodations",
			"loading",
			"settings",
			"filtered_accommodations"
		]),

		full_accommodations_fetched() {
			if (this.accommodations) {
				if (
					this.accommodations[0].hasOwnProperty("media") &&
					this.accommodations[0].media.length > 0
				) {
					return true;
				}
				return false;
			}
			return false;
		}
	},

	methods: {
		get_accommodations() {
			this.loading.accommodations = true;
			let accommodations = new class_accommodations();
			accommodations.get_meta(true);
			// accommodations.set_media(true);
			if (this.administration.meta.languages.length > 1) {
				accommodations.get_translations(true);
			}
			// accommodations.set_services(true);

			if (this.settings.engine_type === "camping") {
				accommodations.set_endpoint("accommodations");
			} else {
				accommodations.set_endpoint("ota/accommodations");
			}

			accommodations.get();
		},

		get_full_accommodations() {
			let accommodations = new class_accommodations();
			this.$store.state.loading.images = true;
			accommodations.get_meta(true);
			accommodations.set_media(true);
			accommodations.set_services(true);

			// if (this.administration.meta.languages.length > 1) {
			accommodations.get_translations(true);
			// }

			if (this.settings.engine_type === "camping") {
				accommodations.set_endpoint("accommodations");
			} else {
				accommodations.set_endpoint("ota/accommodations");
			}

			accommodations.get();
		}
	},

	created() {
		//Clear accommodation when list view is selected
		this.$store.state.accommodation = {};
		this.$store.state.reservation.arrangement = null;
		this.$store.state.reservation.total = null;
		this.$store.state.reservation.place = null;
		this.$store.state.reservation.rows = null;

		if (
			!this.accommodations
			// (!this.accommodations[0].hasOwnProperty("media") &&
			// 	this.accommodation.media.length <= 0)
		) {
			this.get_accommodations();
		}

		if (!this.full_accommodations_fetched) {
			console.log("inhere?");
			this.get_full_accommodations();
		}
	}
};
</script>

<style lang="scss" scoped>
.pagination {
	// display: inline-block;
	margin-left: auto;
	margin-right: auto;
	width: 40%;

	a {
		color: black;
		float: left;
		padding: 8px 16px;
		text-decoration: none;
		transition: 200ms ease-in-out;
		&.active {
			background-color: #01b5d8;
			color: white;
			border-radius: 5px;
		}

		&:hover:not(.active) {
			background-color: #ddd;
			border-radius: 5px;
		}
	}
}
</style>
