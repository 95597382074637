<template>
	<div class="cc_filter" v-if="!loading.accommodations">
		<!-- <div> -->
		<v-row class="align-center" v-if="settings.engine_type === 'ota'">
			<v-col cols="6">
				<p class="cc_label mb-0">{{ $t("camping") }}</p>
			</v-col>
			<v-col cols="6">
				<v-select
					:items="$store.state.parks"
					item-text="name"
					item-value="admin_id"
					@change="set_filter()"
					v-model="filter.park_id"
					clearable
				></v-select>
			</v-col>
		</v-row>

		<!-- <v-divider v-if="settings.engine_type === 'ota'"></v-divider> -->

		<v-row class="align-center" v-if="settings.filter_rentorcamp">
			<v-col cols="6">
				<p class="cc_label mb-0">{{ $t("rentorcamp") }}</p>
			</v-col>

			<v-col cols="6">
				<v-select
					:items="accommodationTypes"
					v-model="filter.acco_type"
					@change="set_filter()"
					item-value="value"
					item-text="name"
					outlined
					hide-details
					:label="$t('selectItem')"
				></v-select>
			</v-col>
		</v-row>

		<!-- <v-divider v-if="settings.filter_rentorcamp"></v-divider> -->

		<v-row class="align-center" v-if="settings.engine_type === 'ota'">
			<v-col cols="6">
				<p class="cc_label mb-0">{{ $t("country") }}</p>
			</v-col>
			<v-col cols="6">
				<v-select
					:items="countries"
					item-value="countries"
					v-model="filter.country"
					@change="set_filter()"
					outlined
					hide-details
					:label="$t('selectItem')"
					clearable
				></v-select>
			</v-col>
		</v-row>

		<!-- <v-divider v-if="settings.engine_type === 'ota'"></v-divider> -->

		<v-row
			class="align-center"
			v-if="(settings.inline || settings.view === $route.name) && administration.meta.languages.length > 1"
		>
			<v-col cols="6">
				<p class="cc_label mb-0">{{ $t("language") }}</p>
			</v-col>
			<v-col cols="6">
				<v-select
					:items="available_translations"
					:item-text="$i18n.locale"
					item-value="value"
					v-model="selected_language"
					outlined
					hide-details
					:label="$t('selectItem')"
				></v-select>
			</v-col>
		</v-row>

		<!-- <v-divider v-if="settings.inline || settings.view === $route.name"></v-divider> -->

		<v-row class="align-center" v-if="settings.filter_accommodation && $route.name === 'search'">
			<v-col col="6">
				<p class="cc_label mb-0">{{ $t("accommodation") }}</p>
			</v-col>
			<v-col col="6">
				<v-select
					:items="accommodations"
					item-text="name"
					:return-object="true"
					v-model="filter.accommodation"
					@change="set_accommodation()"
					outlined
					hide-details
					:label="$t('selectItem')"
					clearable
				></v-select>
				<!-- width="30%" -->
			</v-col>
		</v-row>

		<!-- <v-divider v-if="settings.filter_accommodation && $route.name === 'search'"></v-divider> -->

		<v-row class="align-center" v-if="administration.meta.discount_card_enabled">
			<v-col cols="6">
				<p class="cc_label mb-0">{{ $t("discountCard") }}</p>
			</v-col>

			<v-col cols="6">
				<v-select
					:items="administration.cards"
					item-value="id"
					item-text="name"
					v-model="filter.discount_card"
					@change="set_discount_card()"
					outlined
					hide-details
					:label="$t('selectItem')"
					clearable
				></v-select>
			</v-col>
		</v-row>

		<v-row class="align-center" v-for="(filter, index) in settings.filters" :key="index">
			<v-col cols="6">
				<p class="cc_label mb-0">{{ filter.name[$i18n.locale] }}</p>
			</v-col>

			<v-col cols="6">
				<v-select
					:return-object="return_object(filter)"
					@change="set_filter($event, filter)"
					outlined
					hide-details
					:label="$t('selectItem')"
					clearable
					v-model="settings.filter[filter.meta.key].value"
					:items="customFilterValues(filter)"
					item-value="value"
					item-text="name"
				></v-select>
			</v-col>
		</v-row>
		<!-- </div> -->
	</div>
	<Loader v-else />
</template>

<script>
import { mapState } from "vuex";
import class_accommodations from "@/api/accommodations/class.accommodations";
import parks from "@/api/administrations/class.administrations";
import { Translation } from "@/mixins/Translation.js";

import Loader from "@/components/Loader.vue";

export default {
	name: "AdvancedFilter",
	mixins: [Translation],

	components: {
		Loader
	},

	computed: {
		...mapState([
			"settings",
			"administration",
			// "filter_accommodations",
			"filtered_accommodations",
			"loading",
			"accommodations"
		]),

		accommodationTypes() {
			let types = [
				{
					name: this.$t("rentOrCampValues")[0],
					value: "all"
				},
				{
					name: this.$t("rentOrCampValues")[1],
					value: "rent"
				},
				{
					name: this.$t("rentOrCampValues")[2],
					value: "camp"
				}
			];
			return types;
		},

		countries() {
			return ["Nederland", "Duitsland", "Frankrijk", "Hardcoded"];
		}
	},

	data() {
		return {
			filter: this.$store.state.settings.filter,
			tempFilter: [],
			filter_accommodations: this.$store.state.accommodations
		};
	},

	created() {
		if (this.settings.engine_type === "ota") {
			parks.set_store(this.$store);
			parks.get();
		}

		if (!this.accommodations && this.$route.name !== "list") {
			this.get_accommodations();
		}
	},
	methods: {
		set_filter: function(value, filter) {
			this.filter_accommodations = this.accommodations; //--> Reset to all accommodations to apply all filters
			// if (!value) {
			// 	this.$store.commit("empty_filter", filter);
			// }

			Object.keys(this.settings.filter).forEach(filter => {
				if (this.settings.filter[filter]) {
					if (
						typeof this.settings.filter[filter] === "object" &&
						this.settings.filter[filter].value
					) {
						console.log(this.settings.filter[filter], "filtertje");
						//Custom filter
						this.setCustomFilter(this.settings.filter[filter]);
					} else if (
						typeof this.settings.filter[filter] == "string"
					) {
						//Regular filter
						if (filter === "acco_type") {
							this.set_rentorcamp();
						}
					}
				}
			});

			this.$store.commit(
				"set_filtered_accommodations",
				this.filter_accommodations
			);
		},

		set_rentorcamp() {
			let filtered_accommodations = [];
			let type = this.filter.acco_type;

			if (type === "all") {
				filtered_accommodations = this.filter_accommodations;
			} else if (type === "rent") {
				filtered_accommodations = this.filter_accommodations.filter(
					accommodation => {
						return (
							accommodation.meta.product_acco_type !== "campsite"
						);
					}
				);
			} else if (type === "camp") {
				filtered_accommodations = this.filter_accommodations.filter(
					accommodation => {
						return (
							accommodation.meta.product_acco_type === "campsite"
						);
					}
				);
			}

			this.filter_accommodations = filtered_accommodations;

			// this.$store.commit(
			// 	"set_filtered_accommodations",
			// 	filtered_accommodations
			// );
		},

		setCustomFilter(filter) {
			console.log(filter, "filterje");
			let filtered_accommodations = [];
			if (filter.value) {
				filtered_accommodations = this.filter_accommodations.filter(
					accommodation => {
						if (
							accommodation.meta.hasOwnProperty(filter.meta.key)
						) {
							if (typeof filter.value.value === "object") {
								if (filter.value.value.operator === "<") {
									return (
										accommodation.meta[filter.meta.key] <
										filter.value.value.value
									);
								} else if (
									filter.value.value.operator === ">="
								) {
									return (
										accommodation.meta[filter.meta.key] >=
										filter.value.value.value
									);
								}
							} else {
								return (
									accommodation.meta[filter.meta.key] ==
									filter.value
								);
							}
						}
					}
				);
			}

			this.filter_accommodations = filtered_accommodations;

			// this.$store.commit(
			// 	"set_filtered_accommodations",
			// 	filtered_accommodations
			// );
		},

		set_accommodation() {
			this.$store.commit("set_accommodation", this.filter.accommodation);
		},

		set_discount_card() {
			this.$store.commit("set_discount_card", this.filter.discount_card);
		},

		get_accommodations() {
			this.loading.accommodations = true;

			let accommodations = new class_accommodations();

			accommodations.set_store(this.$store);
			if (this.settings.engine_type !== "ota") {
				accommodations.set_endpoint("accommodations");
			} else {
				accommodations.set_endpoint("ota/accommodations");
			}
			accommodations.get_meta(true);
			accommodations.get_translations(true);
			// accommodations.set_media(true);
			// accommodations.set_services(true);
			// accommodations.set_arrangements(true);

			accommodations.get();
		},

		customFilterValues(filter) {
			let values = [];

			if (!filter.meta.hasOwnProperty("values")) {
				values = [
					{
						value: 1,
						name: "Yes"
					},
					{
						value: 0,
						name: "No"
					}
				];
			} else {
				if (typeof filter.meta.values[0] === "string") {
					/** Array **/
					values = filter.meta.values.map(filter_value => {
						return {
							name: filter_value,
							value: filter_value
						};
					});
				} else {
					/** Array with objects **/
					values = filter.meta.values.map(filter_value => {
						let item = {
							name: filter_value.name,
							value: filter_value
						};

						// delete item.value.name;
						return item;
					});
				}
			}

			return values;
		},

		return_object(filter) {
			if (!filter.meta.hasOwnProperty("values")) {
				return false;
			}

			if (typeof filter.meta.values[0] === "string") {
				return false;
			}

			return true;
		}
	}
};
</script>

<style>
.cc_select.v-text-field > .v-input__control > .v-input__slot:before {
	border-style: none;
}

.cc_select.v-text-field > .v-input__control > .v-input__slot:after {
	border-style: none;
}
</style>
