<template>
	<div class="loader">
		<!-- <div class="loader" :style="{backgroundImage: 'url(' + settings.loader +')'}"> -->
		<v-img :src="settings.loader" contain></v-img>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "Loader",

	computed: {
		...mapState(["settings"])
	}
};
</script>

<style lang="scss" scoped>
.loader {
	// width: auto;
	// height: auto;
	width: 60px;
	height: auto;
	overflow: visible;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>