const variables = {
    colors: {
        cyan: "#01b5d8",
        gray: "#ececec",
        red: "red",
        black: "#000",
        white: "#fff",
        lightGray: "#f9f9f9",
    },
};

export default variables;
