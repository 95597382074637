<template>
	<div class="cc_persons">
		<div class="persons__wrapper" v-if="administration.age_tables.length > 0">
			<v-row
				class="cc_person d-flex align-center justify-space-between"
				v-for="(age_table, index) in administration.age_tables"
				:key="age_table.id"
			>
				<p class="cc_person_info mb-0">
					{{ age_table.name }}
					<br />
					<span
						v-if="age_table.age_to"
					>({{ age_table.age_from + "-" + age_table.age_to + " " + $t("year").toLowerCase() }})</span>
					<span
						v-else
					>({{ $t("from").toLowerCase() + " " + age_table.age_from + " " + $t("year").toLowerCase() }})</span>
				</p>
				<div class="cc_person_select">
					<button class="cc_person_min cc_button_icon" @click="remove_person(index)">
						<icon name="min" />
					</button>
					<input
						class="cc_person_input"
						type="number"
						v-model.number="age_tables[index].amount"
						@input="set_persons(index)"
					/>
					<button class="cc_person_plus cc_button_icon" @click="add_person(index)">
						<icon name="plus" />
					</button>
				</div>
			</v-row>
		</div>

		<div class="persons__wrapper" v-else>
			<v-row class="cc_person d-flex align-center justify-space-between">
				<p class="cc_person_info mb-0">{{ $t("persons") }}</p>
				<div class="cc_person_select">
					<button class="cc_person_min cc_button_icon" @click="remove_person('personen')">
						<icon name="min" />
					</button>
					<input
						class="cc_person_input"
						type="number"
						v-model.number="age_tables.amount"
						@input="set_persons('personen')"
					/>
					<button class="cc_person_plus cc_button_icon" @click="add_person('personen')">
						<icon name="plus" />
					</button>
				</div>
			</v-row>
		</div>
	</div>
</template>

<script>
import icon from "@/api/icons.vue";
import { mapState } from "vuex";
import class_pricecalculation from "@/api/price_calculation/class.price_calculation";

export default {
	name: "Persons",

	components: {
		icon
	},

	computed: {
		...mapState([
			"administration",
			"reservation",
			"accommodation",
			"loading"
		])
	},

	data() {
		return {
			age_tables: this.$store.state.reservation.age_tables
			// persons: 0
		};
	},

	watch: {
		age_tables: {
			deep: true,
			handler(value) {
                if(this.$route.name !== 'list'){
                    console.log("in here?");
                    this.calculate_price(value);
                }
			}
		}
	},

	methods: {
		set_persons: function(index) {
			let total;

			if (this.administration.age_tables.length > 0) {
				if (this.reservation.age_tables[index].amount < 1) {
					this.reservation.age_tables[index].amount = 0;
				}

				total = this.reservation.age_tables.reduce(
					(acc, element) => acc + element.amount,
					0
				);
			} else {
				if (this.reservation.age_tables.amount < 1) {
					this.reservation.age_tables.amount = 0;
				}

				total = this.reservation.age_tables.amount;
			}

			this.$store.commit("set_persons", {
				age_tables: this.reservation.age_tables,
				total_persons: total
			});
		},

		add_person: function(index) {
			if (this.administration.age_tables.length > 0) {
				if (this.accommodation.id) {
					if (
						this.reservation.persons + 1 <=
						this.accommodation.meta.persons_max
					) {
						this.age_tables[index].amount += 1;
					}
				} else {
					this.age_tables[index].amount += 1;
				}
				this.set_persons(index);
			} else {
				this.age_tables.amount += 1;

				//Index not used in this case, so 0
				this.set_persons(-1);
			}
		},
		remove_person: function(index) {
			if (this.administration.age_tables.length > 0) {
				if (this.reservation.persons - 1 >= 1) {
					this.age_tables[index].amount -= 1;
				}
				this.set_persons(index);
			} else {
				if (this.reservation.persons - 1 >= 1) {
					this.age_tables.amount -= 1;
				}

				//Index not used in this case, so 0
				this.set_persons(-1);
			}
		},

		calculate_price(persons) {
			this.loading.options = true;

			let price_calculation = new class_pricecalculation();
			price_calculation.set_accommodation_id(this.accommodation.id);

			if (this.reservation.discount_card) {
				price_calculation.set_discount_card(
					this.reservation.discount_card
				);
			}
			price_calculation.set_amount(persons);

			price_calculation.get_option_rows(true);
			price_calculation.get();
		}
	}
};
</script>
