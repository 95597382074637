import axios from "axios";
import helpers from "../../api/helpers.js";

class arrangements {
    constructor(store) {
        this.store = store;
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    set_accommodation_id(accommodation_id) {
        this.accommodation_id = accommodation_id;
    }

    get() {
        let params = "";

        axios
            .get(helpers.get_api_endpoint(this.store.state, "accommodations/" + this.accommodation_id + "/" + this.endpoint))
            .then((response) => {
                this.store.commit("set_arrangements", response.data);
            })
            .catch((error) => {
                console.log("error: ", error);
                let message = error.response.data.error.message;
                // console.log(message, "arrangements error");
                this.store.state.errors.push(`Error in get_arrangements: ${message}`);
            });
    }
}

export default arrangements;
