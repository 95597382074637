<template>
	<!-- <div v-bind:class="{ popup: !settings.inline }"> -->

	<div class="cc_page">
		<SearchAndBook show_button="false" />

		<div class="cc_book_container_flex">
			<div class="cc_book_container_left" v-if="!loading.options || accommodation.options">
				
				<!-- <v-card outlined width="100%" class="mb-6" v-if="administration.meta.map_image.public_url">
					<v-img
						:src="administration.meta.map_image.public_url"
						width="100%"
						height="100px"
						class="d-flex align-end"
						gradient="to top, rgba(0, 0, 0, 0.6) 0%, transparent 72px"
					>
						<v-btn
							color="transparent"
							:to="{ name: 'map' }"
							dark
						>{{ reservation.place ? $t("place") + ": " + available_places[reservation.place].name : $t("selectPlace") }}</v-btn>
					</v-img>
				</v-card> -->

				<v-card outlined class="mb-12">
					<v-expansion-panels v-model="panel" flat mandatory>
						<v-expansion-panel
							flat
							v-if="!loading.options && accommodation.hasOwnProperty('options') && accommodation.options.length > 0"
						>
							<v-expansion-panel-header>
								<h4>{{ $t("options") }}</h4>
							</v-expansion-panel-header>

							<v-divider />

							<v-expansion-panel-content>
								<v-row class="d-flex align-center" v-for="option in parsed_options" :key="option.id">
									<v-col cols="5" style="overflow-x:hidden" class="px-0 px-md-3 cc_option_name">
										{{ option.name }}
										<span class="hidden-md-and-up">
											<br />
											{{ $t(option.type_pro) }}
										</span>
									</v-col>

									<v-col cols="2" class="hidden-sm-and-down px-0 px-md-3">{{ $t(option.type_pro) }}</v-col>

									<v-col cols="3" class="px-0 px-md-3">
										<!-- v-if="option.amount != null && option.required != true" -->
                                        
                                        <!-- Numeric values -->
										<v-select
											:items="optionAmount(option.amount)"
											:item-value="optionAmount(option.amount)"
											@change="set_option($event, option)"
											v-if="option.required != true && option.amount > 0"
											v-model="option.selected_amount"
											outlined
											hide-details
											dense
										></v-select>

                                        <!-- Per person values -->
                                        <v-select
											:items="optionAmount(reservation.persons)"
											:item-value="optionAmount(reservation.persons)"
											@change="set_option($event, option)"
											v-else-if="option.required != true && option.amount == -1"
											v-model="option.selected_amount"
											outlined
											hide-details
											dense
										></v-select>

                                        <!-- Custom dropdown values -->
                                        <v-select
											:items="option.data.select_data"
											item-value="value"
                                            item-text="text"
											@change="set_option($event, option)"
											v-else-if="option.required != true && option.amount == - 2 && option.data.select_data"
											v-model="option.selected_amount"
											outlined
											hide-details
											dense
										></v-select>

										<span
											v-else
											class="cc_option_included px-0 px-md-3"
										>{{ option.required == true ? $t("required") : "" }}</span>
									</v-col>

									<v-col
										cols="2"
										class="px-0 px-md-3 money"
									>{{ $helpers.get_money(option.type_amount, $store.state) }}</v-col>
								</v-row>

								<v-divider></v-divider>

								<v-btn
									large
									dark
									:color="$variables.colors.cyan"
									depressed
									class="mt-6 mb-2"
									@click="panel = 1"
								>{{ $t("next") }}</v-btn>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- FORM -->
						<v-expansion-panel class="mt-0">
							<v-divider />

							<v-expansion-panel-header>
								<h4>{{ $t("personalInformation") }}</h4>
							</v-expansion-panel-header>

							<v-divider />

							<v-expansion-panel-content>
								<v-form ref="reservationForm" id="book-form" @submit.prevent=" reservate()">
									<!-- GENDER -->
									<v-radio-group v-model="reservation.meta.gender" :mandatory="false" row>
										<div class="cc_label">{{ $t("gender") + ":" }}</div>
										<v-radio :label="$t('male')" value="male" class="ml-4"></v-radio>
										<v-radio :label="$t('female')" value="female"></v-radio>
										<v-radio :label="$t('family')" value="family"></v-radio>
									</v-radio-group>

									<!-- NAME -->
									<v-row class="px-3">
										<v-text-field
											outlined
											v-model="reservation.meta.first_name"
											:rules="rules"
											:label="$t('firstName') + '*'"
											required
											class="mr-8 cc_first_name"
										></v-text-field>

										<v-text-field
											outlined
											v-model="reservation.meta.last_name"
											:rules="rules"
											:label="$t('lastName') + '*'"
											required
											class="cc_last_name"
										></v-text-field>
									</v-row>

									<!-- CITY -->
									<v-row class="px-3">
										<v-text-field
											outlined
											v-model="reservation.meta.zipcode"
											:rules="rules"
											:label="$t('zipCode') + '*'"
											required
											class="mr-8 cc_zipcode"
										></v-text-field>
										<v-text-field
											outlined
											v-model="reservation.meta.city"
											:rules="rules"
											:label="$t('city') + '*'"
											required
										></v-text-field>
									</v-row>

									<!-- ADRES -->
									<v-row class="px-3">
										<v-text-field
											outlined
											v-model="reservation.meta.address"
											:rules="rules"
											:label="$t('address') + '*'"
											required
											class="mr-8"
										></v-text-field>
										<v-text-field
											outlined
											v-model="reservation.meta.address_number"
											:rules="rules"
											:label="$t('houseNumber') + '*'"
											required
											class="cc_address_number"
										></v-text-field>
									</v-row>

									<!-- COUNTRY -->
									<v-row class="px-3 d-flex align-center">
										<v-select
											outlined
											:items="countries"
											item-text="name"
											item-value="code"
											:rules="rules"
											v-model="reservation.meta.country"
											required
											:label="$t('country') + '*'"
										></v-select>
									</v-row>

									<!-- BIRTHDATE -->
									<v-row class="px-3 my-4 d-flex align-center cc_form_birthdate" v-if="settings.birthDate">
										<v-input class="ma-0 mb-2 cc_birth_date_label" hide-details="true">
											{{ $t("birthDay") + ":" }}
											<span class="cc_required">*</span>
										</v-input>

										<v-select
											outlined
											:items="birth_days"
											:rules="rules"
											v-model="reservation.meta.birth.birth_day"
											:label="$t('day')"
											class="cc_birth_date ml-2"
										></v-select>

										<v-select
											outlined
											:items="birth_months"
											:rules="rules"
											v-model="reservation.meta.birth.birth_month"
											:label="$t('month')"
											class="cc_birth_date ml-2"
										></v-select>

										<v-select
											outlined
											:items="birth_years"
											:rules="rules"
											v-model="reservation.meta.birth.birth_year"
											:label="$t('year')"
											class="cc_birth_date ml-2"
										></v-select>
									</v-row>

									<div class="cc_title">
										<h2>{{ $t("other") }}</h2>
									</div>

									<v-divider class="my-4"></v-divider>

									<!-- EMAIL -->
									<v-row class="px-3 d-flex align-center">
										<v-text-field
											outlined
											v-model="reservation.meta.email"
											:rules="emailRules"
											:label="$t('email') + '*'"
											width="60%"
											required
										></v-text-field>
									</v-row>

									<!-- PHONE -->
									<v-row class="px-3 d-flex align-center">
										<v-text-field
											outlined
											v-model="reservation.meta.phone"
											:rules="rules"
											:label="$t('phone') + '*'"
											width="60%"
											required
										></v-text-field>
									</v-row>

									<!-- NOTE -->
									<v-row class="px-3 d-flex align-center">
										<v-textarea
											outlined
											name="note"
											:label="$t('note')"
											v-model="reservation.meta.note_guest"
											rows="3"
										></v-textarea>
									</v-row>

									<v-row class="px-3 d-flex flex-column justify-center">
										<!-- Maybe an xss vulnerability -->
										<p v-html="createTermsString()" />
									</v-row>

									<v-divider class="pb-6"></v-divider>

									<div class="pb-2">
										<v-btn
											type="submit"
											form="book-form"
											:disabled="!allow_booking"
											:loading="loading.pending"
											large
											:color="$variables.colors.cyan"
											depressed
											class="mr-4 white--text"
										>{{ $t("confirm") }}</v-btn>
									</div>
								</v-form>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card>
			</div>
			<Loader v-else />

			<div class="cc_book_container_right">
				<v-card
					v-if="accommodation.id !== undefined"
					:to="{ name: 'accommodation', params: { accommodation_id: accommodation.id } }"
					class="mb-6"
				>
					<v-responsive max-height="200px">
						<v-img
							:src="accommodation.meta.thumb"
							height="200px"
							class="align-end"
							@click="open_accommodation(accommodation)"
							gradient="to top, rgba(0, 0, 0, 0.6) 0%, transparent 72px"
						>
							<v-card-title class="white--text">{{ get_accommodation_title(this.accommodation) }}</v-card-title>
						</v-img>
					</v-responsive>
				</v-card>

				<Reservation />
			</div>
		</div>
	</div>

	<!-- <Pending v-else /> -->
	<!-- </div> -->
</template>

<script>
/* eslint-disable */

import { mapState } from "vuex";
import class_PriceCalculation from "@/api/price_calculation/class.price_calculation.js";
import class_Reservation from "@/api/class.reservation.js";
import countries from "@/assets/countries.json";
import { Price } from "@/mixins/Price.js";
import { Translation } from "@/mixins/Translation.js";

//components
// import icon from "@/api/icons.vue";
import Reservation from "@/components/Reservation";
import SearchAndBook from "@/components/Search";
import Loader from "@/components/Loader.vue";
import Pending from "@/components/Pending.vue";

export default {
	components: {
		SearchAndBook,
		Reservation,
		// icon,
		Loader,
		Pending,
	},

	mixins: [Price, Translation],

	computed: {
		...mapState([
			"accommodation",
			"administration",
			"settings",
			"loading",
			"reservation",
			"errors",
			"available_places",
		]),

		amountOfYears() {
			return this.$moment().year() - 1920;
		},

		parsed_options() {
			let parsed_options = this.accommodation.options;

			parsed_options.forEach((option) => {
				if (option.required !== "1") {
					let exists = this.reservation.option_rows.some(
						(row) => row.type_id === option.id
					);
					let item = this.reservation.option_rows.find(
						(row) => row.type_id === option.id
					);

					option.selected_amount = exists ? item.amount : 0;
				}
			});

			return parsed_options;
		},

		birth_days() {
			let days = [];

			for (let i = 1; i <= 31; i++) {
				days.push(i);
			}

			return days;
		},

		birth_months() {
			let months = [];

			for (let i = 1; i <= 12; i++) {
				months.push(i);
			}

			return months;
		},

		birth_years() {
			let years = [];

			for (let i = this.$moment().format("YYYY"); i >= 1900; i--) {
				years.push(i);
			}
			return years;
		},

		// error_message() {
		// 	let arrival = this.$moment(this.reservation.arrival);
		// 	let departure = this.$moment(this.reservation.departure);
		// 	let duration = departure.diff(arrival, "days");

		// 	if (duration > this.accommodation.meta.duration_max) {
		// 		return `Maximale verblijfsduur overschreden, maximale verblijfsduur is ${this.accommodation.meta.duration_max} dagen.`;
		// 	} else {
		// 		return this.$t("errorAccommodation");
		// 	}
		// }
	},

	data() {
		return {
			// default_value: 0,
			// options_dropped: false,
			// data_dropped: false,
			// age_tables: null,
			countries: countries,
			// currentYear: this.$moment().year(),
			form: this.$store.state.reservation.meta, //Backend:  first and lastname requred
			// error: "",
			rules: [(v) => !!v || this.$t("fieldRequired")],
			emailRules: [
				(v) => !!v || this.$t("fieldRequired"),
				(v) => /.+@.+\..+/.test(v) || this.$t("invalidEmail"),
			],
			panel: 0,
		};
	},

	created() {
		this.foundPrice = true;

		if (!this.loading.options) {
			if (this.accommodation.id === undefined || !this.foundPrice) {
				this.$router.push({ name: "list" });
			}
		}

		if (this.reservation.total <= 0 && !this.loading.options) {
			if (this.check_selection()) {
				this.loader = true;
				this.calculate_price();
			}
			// this.get_priceCalculation();
		}

		// this.age_tables = this.administration.age_tables;
	},

	watch: {
		form: {
			handler() {
				this.$store.commit("set_personal_information", this.form);
			},
			deep: true,
		},
	},

	methods: {
		reservate() {
			// debugger;
			if (this.$refs.reservationForm.validate()) {
				let reservation = new class_Reservation(this.$store);

				if (this.reservation.total <= 0) {
					this.errors.push("Geen prijscalculatie gevonden!");
					this.$router.push({
						name: "list",
					});
				} else {
					this.loading.pending = true;

					reservation
						.create(this.form)
						.then((response) => {
							console.log(response.data);

							if (response.status === 200) {
								if ( this.$gtm.enabled()) {
									this.push_gtm(response.data);
									this.push_gtm_billing(response);
								}

								this.$store.commit(
									"set_created_reservation",
									response.data
								);
								this.$store.commit("set_finished", true);
								this.$router.push({ name: "done" });
							}
						})
						.catch((error) => {
							// let message = error.response.data.error.message;
							console.log(error, "error");
							let message = error;
							this.loading.pending = false;
							this.$store.state.errors.push(
								`Error in creating a reservation: ${message}`
							);
						});
				}
			}
		},

		set_option(value, option) {
			this.loading.add_option = true;
			let class_price_calculation = new class_PriceCalculation();

			// this.loading.recalculate_price = true;

			// class_price_calculation.set_store(this.$store);
			class_price_calculation.set_endpoint(
				"price_calculation/add_option"
			);
			class_price_calculation.set_accommodation_id(
				this.reservation.accommodation_id
			);
			class_price_calculation.set_draft_id(
				this.reservation.calculation_draft_id
			);
			class_price_calculation.set_calculation_id(
				this.reservation.calculation_id
			);
			class_price_calculation.set_option_amount(value);
			class_price_calculation.set_option_id(option.id);

			if (this.reservation.discount_card) {
				class_price_calculation.set_discount_card(
					this.reservation.discount_card
				);
			}

			class_price_calculation.add_option();

			option.selected_amount = value;

			this.$store.commit("change_option_value", {
				option: option,
				value: value,
			});
			// setTimeout(() => {
			//     this.loading.recalculate_price = false;
			// }, 500);
		},

		// get_priceCalculation() {
		// 	if (
		// 		this.accommodation.id &&
		// 		this.reservation.arrival &&
		// 		this.reservation.departure
		// 	) {
		// 		this.loading.options = true;

		// 		let class_price_calculation = new class_PriceCalculation();

		// 		class_price_calculation.set_store(this.$store);
		// 		class_price_calculation.set_arrival(
		// 			this.$moment(this.reservation.arrival).format("YYYY-MM-DD")
		// 		);

		// 		class_price_calculation.set_departure(
		// 			this.$moment(this.reservation.departure).format(
		// 				"YYYY-MM-DD"
		// 			)
		// 		);
		// 		class_price_calculation.set_accommodation_id(
		// 			this.accommodation.id
		// 		);

		// 		class_price_calculation.set_amount(this.reservation.age_tables);
		// 		class_price_calculation.get_option_rows(true);
		// 		class_price_calculation.get();
		// 	}
		// },

		open_accommodation(accommodation) {
			this.$store.commit("set_accommodation", accommodation);

			this.$router.push({ path: "/accommodation/" + accommodation.id });
		},

		optionAmount(max_amount) {
			let amounts = [];

			if (max_amount) {
				for (let i = 0; i <= max_amount; i++) {
					amounts.push(i);
				}
			} else {
				amounts = [0, 1];
			}

			return amounts;
		},

		createTermsString() {
			return;

			if (
				this.administration.meta.terms_url &&
				typeof this.administration.meta.terms_url !== undefined
			) {
				let data = {
					terms: this.administration.meta.terms_url
						? `<a href="${
								this.administration.meta.terms_url
						  }" target="_blank">${this.$t(
								"termsAndConditions"
						  )}</a>`
						: this.$t("termsAndConditions"),
					park: this.administration.name,
				};

				return this.$t("termsofServices", data);
			} else {
				return;
			}
		},

		push_gtm(reservation) {
			
            window.dataLayer.push({
                event: "bookingCreated"
            })

            const arrival = this.$moment(reservation.arrival)
            const departure = this.$moment(reservation.departure);
            const totalNights = departure.diff(arrival, "days");


            window.dataLayer.push({
                bookingData: {
                    park_id: this.$store.state.administration.id,
                    currency: this.$store.state.administration.meta.currency,
                    reservationID: reservation.id,
                    accommodationID: reservation.accommodation_id,
                    arrival: arrival.format("YYYY-MM-DD"),
                    departure: departure.format("YYYY-MM-DD"),
                    totalNights: Number(totalNights),
                    ageTables: reservation.meta.age_table_input,
                    persons: Number(reservation.meta.persons),
                    total: Number(reservation.total),
                    place: reservation.place,
                    placeId: reservation.place_id,
                    created: reservation.create_date
                }
            })

		},

		push_gtm_billing(reservation) {
			return;

			let gtm_obj = {
				transactionId: "1234",
				transactionAffiliation: "Reservation #" + reservation.data.id,
				transactionTotal: reservation.data.total,
				transactionTax: 0,
				transactionShipping: 0,
			};

			window.dataLayer.push(gtm_obj);
		},
	},
};
</script>
