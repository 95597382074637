import axios from "axios";
import moment from "moment";
import helpers from "../../api/helpers.js";
import store from "../../store";

class price_calculation {

    get_options = true;

    constructor() {

        this.endpoint = "price_calculation";
        this.arrival = "";
        this.departure = "";
        this.accommodation_id = "";

        this.arrival = moment(store.state.reservation.arrival).format("YYYY-MM-DD");
        this.departure = moment(store.state.reservation.departure).format("YYYY-MM-DD");

        this.fx = require("money");
        this.fx.base = store.state.administration.meta.currency;
        this.fx.rates = {
            EUR: 1, // eg. 1 USD === 0.745101 EUR
            GBP: 0.9, // etc...
            AUD: 1.63,
            USD: 1.12,
            CAD: 1.53,
            CHF: 1.06,
            CZK: 26.78,
            DKK: 7.45,
            PLZ: 4.46,
        };

        let rates = null;

        axios.get("https://api.exchangeratesapi.io/latest?base=EUR").then((response) => {
            rates = response.data;
            this.fx.rates = response.data.rates;
            return response.data;
        });

        //currency exchanger

        // this.fx.rates = {
        //     EUR: 1, // eg. 1 USD === 0.745101 EUR
        //     GBP: 0.9, // etc...
        //     AUD: 1.63,
        //     USD: 1.12,
        //     CAD: 1.53,
        //     CHF: 1.06,
        //     CZK: 26.78,
        //     DKK: 7.45,
        //     PLZ: 4.46,
        // };

        this.convert = (amount, data) => {

            amount = parseInt(amount);

            if(
                data.to != null &&
                data.from != null &&
                data.to == 'CHF' && 
                data.from == 'EUR' 
                ){
                    return Math.round((amount * 1.08) * 100) / 100 ;
            }

            return amount ;

            // { from: store.state.administration.meta.currency, to: new_currency }

        }
        
    }

    set_store(store) {
        this.store = store;
        this.arrival = moment(this.store.state.reservation.arrival).format("YYYY-MM-DD");
        this.departure = moment(this.store.state.reservation.departure).format("YYYY-MM-DD");
        this.accommodation_id = this.store.state.accommodation.id;
        this.admin_id = this.store.state.administration.id;
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    set_arrival(arrival) {
        this.arrival = arrival;
    }

    set_departure(departure) {
        this.departure = departure;
    }

    set_accommodation_id(accommodation_id) {
        this.accommodation_id = accommodation_id;
    }

    set_admin_id(admin_id) {
        this.admin_id = admin_id;
    }

    get_option_rows(status) {
        this.get_options = status;
    }

    set_draft_id(value) {
        this.calculation_draft_id = value;
    }

    set_calculation_id(value) {
        this.calculation_id = value;
    }

    set_option_amount(amount) {
        this.option_amount = amount;
    }

    set_option_id(id) {
        this.option_id = id;
    }

    set_discount_card(card_id) {
        this.discount_card = card_id;
    }

    set_arrangement_id(arrangement_id) {
        this.arrangement_id = arrangement_id;
    }

    set_amount(persons) {
        if (!Array.isArray(persons)) {
            this.persons_amount = persons.amount;
        } else {
            let persons_object = persons.map((person) => {
                return {
                    id: person.id,
                    count: person.amount,
                };
            });
            this.persons_amount = persons_object;
        }
    }

    get() {
        let cc_state = store.state;
        let params = "";

        // params = params + "&admin_id=" + this.store.state.administration.id;
        params = params + "&arrival=" + this.arrival;
        params = params + "&departure=" + this.departure;
        params = params + "&accommodation_id=" + this.accommodation_id;
        params = params + "&get_options=" + this.get_options + "&get_discounts_price=true&get_required_options_price=true&get_taxes_price=true&get_guests_price=true";

        if (this.discount_card) {
            params = params + "&card_id=" + this.discount_card;
        }

        if (this.arrangement_id) {
            params = params + "&force_price_id=" + this.arrangement_id;
        }

        if (this.persons_amount) {
            if (Array.isArray(this.persons_amount)) {
                params = params + "&age_tables=" + JSON.stringify(this.persons_amount);
            } else {
                params = params + "&persons=" + this.persons_amount;
            }
        }

        axios
            .get(helpers.get_api_endpoint(cc_state, this.endpoint, params))
            .then((response) => {
                let price_calculation_data = response.data;
                let new_currency = cc_settings.currency ? cc_settings.currency : store.state.administration.meta.currency;

                price_calculation_data.accommodation_id = this.accommodation_id;

                price_calculation_data.total = this.convert(price_calculation_data.total, { from: store.state.administration.meta.currency, to: new_currency });

                if(price_calculation_data.available_option_rows){
                    price_calculation_data.available_option_rows.forEach(option => {
                        option.type_amount = this.convert(option.type_amount, { from: store.state.administration.meta.currency, to: new_currency })
                    })
                }

                if(price_calculation_data.option_rows){
                    
                    price_calculation_data.option_rows.forEach(option => {
                        console.log(option);
                        option.total = this.convert(option.total, { from: store.state.administration.meta.currency, to: new_currency })
                    })
                }

                if(price_calculation_data.rows){
                    price_calculation_data.rows.forEach(row => {
                        row.total = this.convert(row.total, { from: store.state.administration.meta.currency, to: new_currency })
                    })
                }

                console.log(response.data);
                store.commit("set_price_calculation", price_calculation_data);
            })
            .catch((error) => {
                let errorMessage = error.response.data.error.message;
                console.log(error.response, "errorrr");
                store.state.errors.push(`Error in get_price_calculation: ${errorMessage}`);
            });
    }

    get_all() {
        let params = "";
        // params = params + "&admin_id=" + this.store.state.administration.id;
        params = params + "&arrival=" + this.arrival;
        params = params + "&departure=" + this.departure;
        params = params + "&accommodation_id=" + this.accommodation_id;
        params = params + "&get_options=" + this.get_options + "&get_discounts_price=true&get_required_options_price=true&get_taxes_price=true&get_guests_price=true";

        if (this.discount_card) {
            params = params + "&card_id=" + this.discount_card;
        }

        if (this.persons_amount) {
            if (Array.isArray(this.persons_amount)) {
                params = params + "&age_tables=" + JSON.stringify(this.persons_amount);
            } else {
                params = params + "&persons=" + this.persons_amount;
            }
        }

        return axios({
            method: "get",
            url: helpers.get_api_endpoint(store.state, this.endpoint, params),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                let calculation = response.data;
                let new_currency = cc_settings.currency ? cc_settings.currency : store.state.administration.meta.currency;

                calculation.total = this.convert(calculation.total, { from: store.state.administration.meta.currency, to: new_currency });

                return calculation;

            })
            .catch((error) => {

                console.log("errorrr", error);

                if(error.response != null){
                    let errorMessage = error.response.data.error.message;
                    store.state.errors.push(`Error in get_price_calculation: ${errorMessage}`);
                }else{
                    store.state.errors.push(`Unknown error - price calculation`);
                }
                
            });
    }

    add_option() {
        if (!this.accommodation_id || !this.calculation_draft_id || !this.calculation_id || this.option_amount === undefined || !this.option_id) {
            throw new Error("Not all requirements are filled");
            return;
        }

        // let params = `&accommodation_id=${this.accommodation_id}&option_ids=[${this.option_id}]&option_value=${this.option_amount}
        // &calculation_id=${this.calculation_id}&calculation_draft_id=${this.calculation_draft_id}`;

        let args = {
            accommodation_id: this.accommodation_id,
            option_ids: [this.option_id],
            option_value: this.option_amount,
            calculation_id: this.calculation_id,
            calculation_draft_id: this.calculation_draft_id
        }

        axios
            .put(helpers.get_api_endpoint(store.state, this.endpoint), args)
            .then((response) => {
                let new_currency = cc_settings.currency ? cc_settings.currency : store.state.administration.meta.currency;
                let price_calculation_data = response.data;

                price_calculation_data.total = this.convert(price_calculation_data.total, { from: store.state.administration.meta.currency, to: new_currency });

                if(price_calculation_data.available_option_rows){
                    price_calculation_data.available_option_rows.forEach(option => {
                        option.type_amount = this.convert(option.type_amount, { from: store.state.administration.meta.currency, to: new_currency })
                    })
                }

                if(price_calculation_data.option_rows){
                    
                    price_calculation_data.option_rows.forEach(option => {
                        console.log(option);
                        option.total = this.convert(option.total, { from: store.state.administration.meta.currency, to: new_currency })
                    })
                }

                if(price_calculation_data.rows){
                    price_calculation_data.rows.forEach(row => {
                        row.total = this.convert(row.total, { from: store.state.administration.meta.currency, to: new_currency })
                    })
                }


                price_calculation_data.accommodation_id = this.accommodation_id;
                store.commit("set_price_calculation", price_calculation_data);
            })
            .catch((error) => {
                console.log(error, "error");
            });
    }
}

export default price_calculation;
