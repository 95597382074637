import axios from "axios";
import helpers from "@/api/helpers.js";
import moment from "moment";

class places {
    constructor(store) {
        this.store = store;
        this.state = store.state;
        // this.endpoint = endpoint;
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    set_accommodation(id) {
        this.accommodation_id = id;
    }

    set_admin_id(id) {
        this.admin_id = id;
    }

    get_all() {
        if (!this.state.reservation.arrival || !this.state.reservation.departure) {
            return;
        }

        let params = "";

        // if (this.admin_id) {
        //     params = params + "&admin_id=" + this.admin_id;
        // }

        params = params + "&arrival=" + moment(this.state.reservation.arrival).format("YYYY-MM-DD");
        params = params + "&departure=" + moment(this.state.reservation.departure).format("YYYY-MM-DD");

        if (this.accommodation_id) {
            params = params += "&accommodation_id=" + this.accommodation_id;
        }

        axios({
            method: "get",
            url: helpers.get_old_api_endpoint(this.state, this.endpoint, params),
        })
            .then((response) => {
                this.store.commit("set_available_places", response.data);
                return true;
            })
            .catch((error) => {
                let errorMessage = error.response.data.error.message;
                console.log(error.response, "errorrr");
                this.store.state.errors.push(`Error in get_all_places: ${errorMessage}`);
                return false;
            });
        return true;
    }

    get_per_accommodation(accommodation_id) {}
}

export default places;
