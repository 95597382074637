<template :key="keyUpdate">
	<div class="cc_map">
		<Search />

		<v-dialog v-model="show_popup" :width="width">
			<v-card v-if="!loading.accommodation && show_popup">
				<v-card-title class="d-flex align-center justify-space-between">
					<div class="cc_popup_header_icon">
						<div class="cc_button_icon">
							<icon name="map" />
						</div>
					</div>

					<div class="cc_popup_header_center">{{ $t("placeInformation") }}</div>

					<div class="cc_popup_header_icon">
						<div class="cc_button_icon" v-on:click="show_popup = false">
							<icon name="close" />
						</div>
					</div>
				</v-card-title>

				<v-card-text class="cc_popup_content">
					<v-responsive>
						<v-img :src="accommodation.meta.thumb" class="thumb"></v-img>
					</v-responsive>
					<!-- <div class="thumb" :style="'background-image: url(' + accommodation.meta.thumb + ');'"></div> -->
					<div class="cc_title my-4">
						<h3>{{ accommodation.translations[settings.language].name }}</h3>
						<p class="mb-0">{{ $t("place") + ": " + available_places[place].name }}</p>
					</div>
					<v-divider class="mb-4 mt-0"></v-divider>
					<div class="cc_content">
						<h2>{{$t('moreInformation')}}</h2>
						<p class="mt-2">{{accommodation.translations[settings.language].description}}</p>
						<!-- <h4>{{ $t("placeSaved") }}</h4>
						<br />
						<icon name="done" />-->
					</div>
				</v-card-text>

				<v-card-actions class="cc_popup_footer px-6">
					<v-row>
						<v-col cols="6" v-if="fromBooked">
							<v-dialog v-model="show_conformation" width="500" style="z-index:99" persistent>
								<template v-slot:activator="{ on }">
									<v-btn
										color="#01b5d8"
										dark
										large
										width="100%"
										height="50px"
										v-on="on"
										@click="selectPlace(available_places[place].id)"
									>{{ $t("save") }}</v-btn>
								</template>

								<v-card z-index="99">
									<v-card-title class="headline lighten-2" primary-title>{{$t("placeSaved")}}</v-card-title>
									<v-card-text>
										<icon name="done" />
									</v-card-text>

									<v-divider></v-divider>

									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn text :to="{name: 'book'}">{{$t("continue")}}</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</v-col>
						<v-col :cols="fromBooked ? '6' : '12'">
							<v-btn
								color="#ececec"
								width="100%"
								height="50px"
								large
								:to="'/accommodation/' + accommodation.id"
							>{{ $t("moreInformation") }}</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
			<Loader v-else />
		</v-dialog>

		<div class="cc_map__wrapper" v-if="!loading.places">
			<Map v-if="mapAvailable" />
			<v-card v-else width="80%" class="cc_map__not-available mt-6">
				<v-card-title class="d-flex justify-center">{{$t("mapNotAvailable")}}</v-card-title>

				<v-card-actions>
					<v-row class="d-flex justify-center">
						<v-col cols="6">
							<v-btn
								color="#01b5d8"
								dark
								large
								width="100%"
								height="50px"
								href="javascript:window.history.back();"
							>{{ $t("back") }}</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
				<!-- <h3 v-else>De accommodatie is niet via de kaart te boeken!</h3> -->
			</v-card>
		</div>
		<Loader v-else />
	</div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/api/icons.vue";
import class_places from "@/api/places/class.places.js";

//components
import Map from "@/components/Map.vue";
import Search from "@/components/Search.vue";
import Loader from "@/components/Loader.vue";

export default {
	components: {
		Map,
		Icon,
		Search,
		Loader
	},

	computed: {
		...mapState([
			"accommodation",
			"reservation",
			"available_places",
			"administration",
			"loading",
			"settings"
		]),

		dates() {
			/**
			 * When @dates change, the watcher is trigger to get the new available places
			 */
			return {
				arrival: this.reservation.arrival,
				departure: this.reservation.departure,
				place: null
			};
		},

		fromBooked() {
			return this.prevRoute.includes("book");
		},

		width() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				return "35%";
			}
			if (this.$vuetify.breakpoint.smAndDown) {
				return "100%";
			}

            return "100%"
		},

		mapAvailable() {
			let availablePlaces = Object.keys(this.available_places);
			let value = false;

			this.administration.meta.map_data.forEach(place => {
				if (availablePlaces.includes(place[4])) {
					value = true;
				}
			});

			return value;
		}
	},

	data() {
		return {
			isMobile: window.innerWidth <= 500,
			show_popup: false,
			show_conformation: false,
			selected_place: 0,
			prevRoute: ""
		};
	},

	watch: {
		dates: {
			handler(item) {
				this.get_available_places();
			},
			deep: true
		}
	},

	methods: {
		onResize(event) {
			this.$nextTick(function() {
				this.isMobile = window.innerWidth <= 500;
			});
		},

		get_available_places() {
			let places = new class_places(this.$store);
			places.set_endpoint("places/available");
			places.set_admin_id(this.administration.id);

			if (this.fromBooked) {
				places.set_accommodation(this.reservation.accommodation_id);
			}

			places.get_all();
		},

		selectPlace(place_id) {
			if (this.accommodation.id === undefined) {
				this.get_accommodation(
					this.available_places[place_id].accommodation_id
				);
			}
			this.$store.commit("set_selected_place", place_id);
			// this.$router.push({ name: "book" });
		}
	},

	created() {
		this.prevRoute = this.$route.meta.props.prevUrl;

		let node = document.createElement("meta");
		node.setAttribute("name", "viewport");
		node.setAttribute(
			"content",
			"width=device-width, initial-scale=1, user-scalable=no"
		);

		document.getElementsByTagName("head")[0].appendChild(node);
		document.getElementsByTagName("html")[0].style.overscrollBehaviorY =
			"contain";
		document.getElementsByTagName("body")[0].style.overscrollBehaviorY =
			"contain";

		// window.addEventListener("resize", this.onResize);

		this.get_available_places();
	},

	beforeRouteEnter(to, from, next) {
		to.meta.props = {
			prevUrl: from.path
		};
		next();
	}
};
</script>