import axios from "axios";
import helpers from "../../api/helpers.js";
import store from "../../store";
// import price_calculation from "../price_calculation/class.price_calculation";

class accommodations {
    constructor() {
        this.endpoint = "accommodations";
        this.acco_type = "";
        this.park_id = "";
    }

    set_store(store) {
        this.store = store;
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    set_acco_type(acco_type) {
        this.acco_type = acco_type;
    }

    set_park_id(park_id) {
        this.park_id = park_id;
    }

    get_meta(meta) {
        this.set_meta = meta;
    }

    set_media(media) {
        this.get_media = media;
    }

    get_translations(translation) {
        this.set_translations = translation;
    }

    set_services(value) {
        this.get_services = value;
    }

    set_arrangements(value) {
        this.get_arrangements = value;
    }

    get_ota() {
        let cc_state = store.state;
        let params = "";

        if (this.acco_type != "") {
            params = "&acco_type=" + this.acco_type;
        }

        // if (this.park_id != "") {
        //     params = "&admin_id=" + this.park_id;
        // }

        axios
            .get(helpers.get_api_endpoint(cc_state, this.endpoint, params))
            .then((response) => {
                let new_accommodation_list = [];

                response.data.forEach(function(accommodation, key) {
                    accommodation.images = [];

                    let park = cc_state.parks.find((park) => park.admin_id == accommodation.admin_id);

                    if (park) {
                        accommodation.park_name = park.name;
                    } else {
                        accommodation.park_name = "";
                    }

                    accommodation.media.forEach(function(media, key) {
                        let media_data = JSON.parse(media.data);

                        let tmp_image = {
                            src: media_data.public_url,
                            thumbnail: media.thumb,
                            w: media_data.width,
                            h: media_data.height,
                            alt: accommodation.name,
                        };

                        accommodation.images.push(tmp_image);
                    });

                    new_accommodation_list.push(accommodation);
                });

                this.store.commit("set_accommodations", new_accommodation_list);
            })
            .catch((error) => {
                let errorMessage = error.response.data.error.message;
                console.log(error.response, "errorrr");
                this.store.state.errors.push(`Error in get_ota_accommodations: ${errorMessage}`);
            });
    }

    get() {
        let cc_state = store.state;
        let params = "";

        if (this.acco_type != "") {
            params = "&acco_type=" + this.acco_type;
        }

        // if (this.park_id != "") {
        //     params = "&admin_id=" + this.park_id;
        // }

        if (this.get_meta) {
            params = params += "&get_meta=" + this.set_meta;
        }

        if (this.get_media) {
            params = params += "&get_media=" + this.get_media;
        }

        if (this.get_translations) {
            params = params += "&get_translations=" + this.set_translations;
        }

        if (this.get_services) {
            params = params += "&get_services=" + this.get_services;
        }

        if (this.get_arrangements) {
            params = params += "&get_arrangements=" + this.get_arrangements;
        }

        // params = params += "&gaet_media=true";
        axios
            .get(helpers.get_api_endpoint(cc_state, this.endpoint, params))
            .then((response) => {
                let new_accommodation_list = [];

                response.data.forEach(function(accommodation) {
                    accommodation.images = [];

                    let park = cc_state.parks.find((park) => park.admin_id == accommodation.admin_id);

                    if (park) {
                        accommodation.park_name = park.name;
                    } else {
                        accommodation.park_name = "";
                    }

                    if (accommodation.hasOwnProperty("media") && accommodation.media.length > 0) {
                        accommodation.media.forEach(function(media) {
                            let media_data = JSON.parse(media.data);

                            let tmp_image = {
                                src: media_data.public_url,
                                thumbnail: media.thumb,
                                w: media_data.width,
                                h: media_data.height,
                                alt: accommodation.name,
                                type_id: media.type_id
                            };

                            accommodation.images.push(tmp_image);
                        });
                    }

                    accommodation.meta.persons_min = parseInt(accommodation.meta.persons_min);
                    accommodation.meta.persons_max = parseInt(accommodation.meta.persons_max);
                    new_accommodation_list.push(accommodation);
                });
                store.commit("set_accommodations", new_accommodation_list);
            })
            .catch((error) => {
                console.log(error, "error");
                let errorMessage = error.response.data.error.message;
                this.store.state.errors.push(`Error in get_accommodations: ${errorMessage}`);
            });
    }
}

export default accommodations;
