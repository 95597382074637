<template>
	<div class="cc_datepicker">
		<cc-date-picker
			ref="datePicker"
			mode="range"
			v-model="range"
			:is-inline="inline"
			:input-props="{ placeholder: 'Select arrival and departure date', readonly: true }"
			:columns="columns()"
			:rows="rows()"
			:locale="$i18n.locale"
			:disabled-dates="disabledDates"
			:attributes="accommodation.id ? calendarAttributes : []"
			is-expanded
		/>
		<v-snackbar
			v-model="open_snackbar"
		>{{ $t("The selected dates are not valid arrival and departure dates.") }}</v-snackbar>
	</div>
</template>

<script>
import Vue from "vue";
import VCalendar from "v-calendar";
import { mapState } from "vuex";
import { extendMoment } from "moment-range";

import price_calculation from "../api/price_calculation/class.price_calculation";

Vue.use(VCalendar, {
	// firstDayOfWeek: 2,
	componentPrefix: "cc"
});

export default {
	name: "Calendar",
	components: {
		VCalendar
	},

	props: { inline: false, vertical: false },

	data() {
		return {
			moment: extendMoment(this.$moment),
			arrival_days: this.$store.state.accommodation
				? this.$store.state.accommodation.arrival_days
				: [],
			departure_days: this.$store.state.accommodation
				? this.$store.state.accommodation.departure_days
				: [],
			calendarAttributes: [],
			open_snackbar: false,
			init: true
		};
	},

	computed: {
		...mapState(["accommodation", "loading", "reservation"]),

		range: {
			get: function() {
				return {
					start: this.reservation.arrival
						? this.moment(this.reservation.arrival).toDate()
						: null,
					end: this.reservation.departure
						? this.moment(this.reservation.departure).toDate()
						: null
				};
			},

			set: function(dates) {
				if (
					this.valid_day(dates.start, this.arrival_days) &&
					this.valid_day(dates.end, this.departure_days)
					// this.$helpers.day_is_valid(
					// 	dates.start,
					// 	this.arrival_days
					// ) &&
					// this.$helpers.day_is_valid(dates.end, this.departure_days)
				) {
					/** When dates are correct **/

					//Close calendar and save new dates
					if (!this.init) {
						this.$parent.$parent.$parent.$parent.dialog_calendar = false;
					}
					this.$store.commit("dates_change", dates);

					//Recalculate price
					if (this.accommodation.id) {
						this.loading.options = true;

						let class_price_calculation = new price_calculation();

						class_price_calculation.set_store(this.$store);
						class_price_calculation.set_arrival(
							this.moment(dates.start).format("YYYY-MM-DD")
						);
						class_price_calculation.set_departure(
							this.moment(dates.end).format("YYYY-MM-DD")
						);
						class_price_calculation.set_accommodation_id(
							this.accommodation.id
						);

						if (this.reservation.discount_card) {
							class_price_calculation.set_discount_card(
								this.reservation.discount_card
							);
						}
						class_price_calculation.set_amount(
							this.reservation.age_tables
						);

						class_price_calculation.get_option_rows(true);
						class_price_calculation.get();
					}
				} else {
					/** WHen dates are not correct **/
					this.$store.commit("empty_dates");
					this.range.start = null;
					this.range.end = null;
					this.open_snackbar = true;

					setTimeout(() => {
						this.open_snackbar = false;
					}, 5000);
				}

				if (this.init) {
					this.init = false;
				}
			}
		},

		disabledDates() {
			/**Default data, others from accommodation will be set in setDisabledDates() */
			return [
				{
					start: "",
					end: this.moment()
						.subtract(1, "days")
						.toDate()
				},
				{
					start: this.moment()
						.add(548, "days")
						.toDate(),
					end: ""
				}
			];
		},

		//Inserts exceptions into calendar
		arrival_days_exceptions() {
			let dates = [];

			if (this.arrival_days.exceptions != null && Array.isArray(this.arrival_days.exceptions)) {

				this.arrival_days.exceptions.forEach(element => {
					dates.push({
						start: element.start,
						end: element.end,
						weekdays: element.days
					});
				});
			}

			return dates;
		},

		//insert exceptions into calendar
		departure_days_exceptions() {
			let dates = [];

			if (this.departure_days.exceptions != null && Array.isArray(this.departure_days.exceptions)) {

				this.departure_days.exceptions.forEach(element => {
					dates.push({
						start: element.start,
						end: element.end,
						weekdays: element.days
					});
				});
				
			}

			return dates;
		}
	},

	created() {
		if (this.accommodation.id) {
			this.accommodation.datepickerSettings.forEach(setting => {
				this.calendarAttributes.push(setting);
			});

			this.calendarAttributes[0].dates = this.arrival_days_exceptions; //Sets exceptions
			this.calendarAttributes[1].dates = this.departure_days_exceptions; //sets exceptions

			this.setDisabledDates();

			/**Inserts default dates with exceptions */
			this.setDefaultDates(
				this.arrival_days,
				this.accommodation.datepickerSettings[0],
				0
			);

			this.setDefaultDates(
				this.departure_days,
				this.accommodation.datepickerSettings[1],
				1
			);
			/******/
		}
	},

	methods: {

		valid_day(day, dates) {

			day = this.moment(day).format("YYYY-MM-DD");

			let is_valid = false;
			let dayNumber = this.moment(day).day() + 1;
			let day_in_exception = false;

			let range = "";
			if (dates) {

				if (dates.exceptions == []) {
					dates.exceptions = null;
				}

				if (
					!dates.hasOwnProperty("exceptions") &&
					dates.exceptions.length <= 0
				) {
					dates.exceptions = null;
				}

				if (dates.exceptions != null && Array.isArray(dates.exceptions)) {

					dates.exceptions.forEach(exception => {

						range = this.moment().range(
							exception.start,
							exception.end
						);

						if (range.contains(this.moment(day))) {
							day_in_exception = true;
							if (exception.days.includes(dayNumber)) {
								is_valid = true;
							}
						}

					});

				}

				if (!day_in_exception) {

					// debugger;
					if (!dates.hasOwnProperty("default")) {
						dates.default = [1, 2, 3, 4, 5, 6, 7];
					}

					if (dates.default && dates.default.includes(dayNumber)) {
						is_valid = true;
					}
				}

				return is_valid;

			}

			return true;

		},

		rows: function(element) {
			if (this.vertical) {
				return 2;
			} else {
				return 1;
			}
		},

		columns: function(element) {
			if (this.vertical) {
				return 1;
			} else {
				return this.$screens({ default: 1, lg: 2 });
			}
		},

		setDisabledDates: function() {
			/**
			 * Pushes dates_without_stock to disabledDates arr
			 */
			this.accommodation.meta.dates_without_stock.forEach(date => {
				this.disabledDates.push(date);
			});
		},

		/**
		 * Function for setting exception days. Loops over one and a half year. Builds objects that are the default dates.
		 * If start day of exception is a default day, subtract one of it.
		 *
		 * @param dates
		 * @param datepickerSetting
		 * @param indexToPush
		 */
		setDefaultDates: function(dates, datepickerSetting, indexToPush) {
			if (indexToPush < 0 || indexToPush > 1) {
				return;
			}

			let now;
			let counter = 0;

			let endDate;
			let startDate;
			let dException;

			if (dates.exceptions && dates.exceptions.length > 0) {
				while (counter !== 548) {
					now = this.moment()
						.add(counter, "days")
						.format("YYYY-MM-DD");

					dException = dates.exceptions.filter(e => e.start === now);
					if (dException.length > 0) {
						dException = dException[0];
						if (!endDate) {
							//If first exception

							this.calendarAttributes[indexToPush].dates.push({
								start: this.moment().toDate(),
								end: this.moment(dException.start)
									.add(1, "days")
									.toDate(),
								weekdays: dates.default
							});
						} else {
							//Second and more exceptions

							startDate = dException.start;
							if (
								this.exceptionDateisDefault(
									startDate,
									dates.default
								)
							) {
								startDate = this.moment(startDate)
									.subtract(1, "days")
									.toDate();
							}

							this.calendarAttributes[indexToPush].dates.push({
								start: endDate,
								end: startDate,
								weekdays: dates.default
							});
						}
						endDate = this.moment(dException.end)
							.add(1, "days")
							.toDate();
					}
					counter = counter += 1;
				}

				/** last end exceptiondate to future  **/
				this.calendarAttributes[indexToPush].dates.push({
					start: endDate,
					end: this.moment().add(548, "days").toDate,
					weekdays: dates.default
				});
			} else if (!dates.default || dates.default.length > 0) {
				if (dates.hasOwnProperty("default")) {
					this.calendarAttributes[indexToPush].dates.push({
						weekdays: dates.default
					});
				} else {
					this.calendarAttributes[indexToPush].dates.push({
						weekdays: [1, 2, 3, 4, 5, 6, 7]
					});
				}
			}
		},

		exceptionDateisDefault: function(date, defaultDays) {
			let dateDay =
				this.moment(date).day() === 7 ? 1 : this.moment(date).day() + 1;
			return defaultDays.includes(dateDay);
		}
	}
};
</script>

<style>
.vc-text-gray-400 {
	color: #ff6e6e;
}
</style>
