import axios from "axios";
import helpers from "../../api/helpers.js";
import store from "../../store";
// import price_calculation from "../price_calculation/class.price_calculation";

class accommodation {
    constructor() {
        this.endpoint = "accommodations";
        this.accommodation_id = 0;
        this.media = true;
        this.admin_id = "";
    }

    set_store(store) {
        this.store = store;
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    set_accommodation_id(accommodation_id) {
        this.accommodation_id = accommodation_id;
    }

    set_admin_id(admin_id) {
        this.admin_id = admin_id;
    }

    get_translations(value) {
        this.translations = value;
    }

    set_media(media) {
        this.get_media = media;
    }

    set_meta(meta) {
        this.get_meta = meta;
    }

    set_services(value) {
        this.get_services = value;
    }

    set_arrangements(value) {
        this.get_arrangements = value;
    }

    get() {
        // let cc_store = this.store;
        // let cc_store = this.store.state;
        let params = "";

        // if (this.store.state.administration.id) {
        //     params = params + "&admin_id=" + this.store.state.administration.id;
        // }

        if (this.media) {
            params = params + "&get_media=true";
        }

        if (this.translations) {
            params = params + "&get_translations=" + this.translations;
        }

        if (this.get_meta) {
            params = params += "&get_meta=" + this.get_meta;
        }

        if (this.get_media) {
            params = params += "&get_media=" + this.get_media;
        }

        if (this.get_services) {
            params = params += "&get_services=" + this.get_services;
        }

        if (this.get_arrangements) {
            params = params += "&get_arrangements=" + this.get_arrangements;
        }

        params = params + "&public_key=" + store.state.settings.public_key;

        return axios
            .get(helpers.get_api_endpoint(store.state, this.endpoint + "/" + this.accommodation_id, params))
            .then((response) => {

                let accommodation = response.data;

                let park = store.state.parks.find((park) => park.admin_id == accommodation.admin_id);

                if (park) {
                    accommodation.park_name = park.name;
                } else {
                    accommodation.park_name = "";
                }

                accommodation.images = [];

                accommodation.media.forEach(function(media, key) {

                    let media_data = JSON.parse(media.data);

                    let tmp_image = {
                        src: media_data.public_url,
                        thumbnail: media.thumb,
                        w: media_data.width,
                        h: media_data.height,
                        alt: accommodation.name,
                        type_id: media.type_id
                    };

                    accommodation.images.push(tmp_image);
                    
                });

                store.commit("set_accommodation", accommodation);
                
                return response;
            })
            .catch((error) => {
                
                console.log("error class.accommodation.js", error) ;

                let errorMessage = "err" ;

                if(
                    error.response != null &&
                    error.response.data != null &&
                    error.response.data.error != null &&
                    error.response.data.error.message != null
                    ){

                    errorMessage = error.response.data.error.message ;

                }
                
                this.store.state.errors.push('Error in get_accommodation');
            
            });

    }
}

export default new accommodation();
