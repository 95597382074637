<template>
	<Loader v-if="loading.accommodations || loading.arrangements" />
	<v-container v-else>
		<v-row class="d-flex align-center">
			<v-col cols="6">{{$t("accommodation")}}</v-col>
			<v-col cols="6">
				<v-select
					:items="accommodations"
					item-text="name"
					:return-object="true"
					width="30%"
					v-model="selected_accommodation"
					@change="set_arrangements()"
					outlined
					hide-details
					:label="$t('selectItem')"
					clearable
				></v-select>
			</v-col>
		</v-row>
		<v-row v-for="arrangement in arrangements" :key="arrangement.id" class="d-flex align-center">
			<v-col cols="8" sm="4">
				<span style="font-weight: 600;">{{arrangement.name}}</span>
				<span v-if="$vuetify.breakpoint.smAndDown">
					<br />
					{{show_date(arrangement.start_date) + "/" + show_date(arrangement.end_date)}}
					<br />
					{{$helpers.get_money(arrangement.price, $store.state)}}
				</span>
			</v-col>
			<v-col
				cols="4"
				v-if="$vuetify.breakpoint.mdAndUp"
			>{{show_date(arrangement.start_date) + "/" + show_date(arrangement.end_date)}}</v-col>
			<v-col
				cols="2"
				v-if="$vuetify.breakpoint.mdAndUp"
			>{{$helpers.get_money(arrangement.price, $store.state)}}</v-col>
			<v-spacer></v-spacer>
			<v-col cols="4" sm="2">
				<v-btn
					width="100%"
					dark
					:color="$variables.colors.cyan"
					@click="select_arrangement(arrangement)"
				>{{$t('book')}}</v-btn>
			</v-col>
		</v-row>
		<v-row v-if="arrangements.length <=0 && selected_accommodation">
			<v-col cols="12">Geen arrangementen voor dit type accommodatie gevonden!</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
// import moment from "moment";
import class_arrangements from "@/api/arrangements/class.arrangements";
import class_accommodations from "@/api/accommodations/class.accommodations";
import class_pricecalculation from "@/api/price_calculation/class.price_calculation.js";

import Loader from "@/components/Loader.vue";

export default {
	components: {
		Loader
	},

	computed: {
		...mapState([
			"accommodations",
			"loading",
			"settings",
			"arrangements",
			"reservation"
		])
	},

	data() {
		return {
			selected_accommodation: null
		};
	},

	created() {
		this.$store.state.arrangements = [];
		if (!this.accommodations) {
			this.loading.accommodations = true;
			let accommodations = new class_accommodations();
			accommodations.set_store(this.$store);
			accommodations.get_meta(true);
			accommodations.set_media(true);
			accommodations.get_translations(true);
			accommodations.set_services(true);
			accommodations.set_arrangements(true);

			if (this.settings.engine_type === "camping") {
				accommodations.set_endpoint("accommodations");
			} else {
				accommodations.set_endpoint("ota/accommodations");
			}
			accommodations.get();
		}
	},

	methods: {
		set_arrangements() {
			console.log(this.selected_accommodation, "accommodation");
			if (this.selected_accommodation) {
				this.loading.arrangements = true;
				let arrangements = new class_arrangements(this.$store);
				arrangements.set_endpoint("arrangements");
				arrangements.set_accommodation_id(
					this.selected_accommodation.id
				);
				arrangements.get();
			}
		},

		select_arrangement(arrangement) {
			/**
			 * Change arrival and departure. X
			 * Make new price calculation X
			 * Send to book page
			 * Show popup
			 */
			console.log(arrangement, "selected_arrangement");
			this.$store.commit(
				"set_accommodation",
				this.selected_accommodation
			);
			this.$store.commit("select_arrangement", arrangement);
			// this.calculate_price();

			this.$router.push({ name: "book" });
		},

		show_date: function(datestring) {
			return this.$moment(datestring).format("DD-MM-YYYY");
		},

		calculate_price() {
			this.loading.recalculate_price = true;
			this.loading.options = true;

			if (this.reservation.arrival && this.reservation.departure) {
				let price_calculation = new class_pricecalculation();
				// price_calculation.set_store(this.$store);
				price_calculation.set_accommodation_id(
					this.selected_accommodation.id
				);

				if (this.reservation.discount_card) {
					price_calculation.set_discount_card(
						this.reservation.discount_card
					);
				}

				if (this.reservation.arrangement) {
					price_calculation.set_arrangement_id(
						this.reservation.arrangement.id
					);
				}

				price_calculation.get();
			}
		}
	}
};
</script>

