<template>
	<v-app class="cc_app">
		<Header v-if="show_header()" />
		<Error :error="error" v-for="(error, index) in errors" :key="index" />
		<Loader v-if="loading.administration" />
		<v-main class="pt-8" v-else>
			<!-- <transition name="fade" mode="out-in">
				<router-view />
			</transition>-->

			<router-view />
		</v-main>
		<Footer v-if="show_footer()" />
	</v-app>
</template>

<script>
import class_administration from "@/api/administrations/class.administration.js";
// import class_campingcare from "@/api/class.campingcare.js";
import class_payments from "@/api/payments/class.payments.js";
import { mapState } from "vuex";

//components
import Header from "@/components/Header.vue";
import Error from "@/components/Error.vue";
import Loader from "@/components/Loader.vue";
import Footer from "@/components/Footer.vue";

export default {
	components: {
		Error,
		Header,
		Loader,
		Footer
	},

	computed: {
		...mapState([
			"settings",
			"loading",
			"administration",
			"errors",
			"reservation"
		])
	},

	created() {
		this.set_attributes();
		this.get_administration();
		this.get_payment_providers();

		let favicon = cc_settings.favicon
			? cc_settings.favicon
			: this.settings.favicon;
		let node = document.createElement("link");
		node.setAttribute("rel", "icon");
		node.setAttribute("href", favicon);

		document.getElementsByTagName("head")[0].appendChild(node);
		//error with favicon --> add code to mounted hook
	},

	methods: {
		set_attributes() {
			this.settings.language = cc_settings.language
				? cc_settings.language
				: this.settings.language;

			this.settings.filters = cc_settings.filters
				? cc_settings.filters
				: this.settings.filters;
			this.set_filters(this.settings.filters);

			this.settings.inline = cc_settings.inline
				? cc_settings.inline
				: this.settings.inline;

			this.settings.engine_type = cc_settings.engine_type
				? cc_settings.engine_type
				: this.settings.engine_type;

			this.administration.id = cc_settings.admin_id
				? cc_settings.admin_id
				: this.administration.id;

			this.settings.view = cc_settings.view
				? cc_settings.view
				: this.settings.view;

			this.settings.filter_accommodation = cc_settings.filter_accommodation
				? cc_settings.filter_accommodation
				: this.settings.filter_accommodation;

			this.settings.birthDate = cc_settings.birthDate
				? cc_settings.birthDate
				: this.settings.birthDate;

			this.settings.loader = cc_settings.loader
				? cc_settings.loader
				: this.settings.loader;

			this.settings.public_key = cc_settings.public_key
				? cc_settings.public_key
				: this.settings.public_key;

			this.settings.channel_id = cc_settings.channel_id
				? cc_settings.channel_id
				: this.settings.channel_id;

			if (!window.sessionStorage.getItem("store")) {
				this.$store.state.reservation.arrival = this.$moment(
					cc_settings.arrival
						? cc_settings.arrival
						: this.$store.state.reservation.arrival
				).format("YYYY-MM-DD");

				if (cc_settings.departure && !cc_settings.duration) {
					this.$store.state.reservation.departure = this.$moment(
						cc_settings.departure
					).format("YYYY-MM-DD");
				} else if (
					cc_settings.duration ||
					(!cc_settings.duration && !cc_settings.departure)
				) {
					let start = this.$moment();
					let duration = cc_settings.duration
						? cc_settings.duration
						: 7;

					if (cc_settings.arrival) {
						start = this.$moment(cc_settings.arrival);
					}
					this.$store.state.reservation.departure = start
						.add(duration, "days")
						.format("YYYY-MM-DD");
				}
			}
		},

		toggleBodyClass(addRemoveClass, className) {
			const el = document.body;

			if (addRemoveClass === "addClass") {
				el.classList.add(className);
			} else {
				el.classList.remove(className);
			}
		},

		show_header() {
			if (
				this.settings.view !== this.$route.name &&
				!this.settings.inline &&
				!this.loading.administration
			) {
				return true;
			}

			return false;
		},

		show_footer() {
			if (
				this.settings.view !== this.$route.name &&
				!this.settings.inline &&
				!this.loading.administration &&
				this.$route.name !== "map"
			) {
				return true;
			}
			return false;
		},

		get_administration() {
			this.loading.administration = true;

			let administration = new class_administration(
				this.administration.id,
				this.$store
			);

			administration.get_age_tables(true);
			administration.get_discount_cards(true);
            administration.get_meta();
			administration.get().then(response => {
				if (
					cc_settings.date_tables &&
					Array.isArray(cc_settings.date_tables) &&
					cc_settings.date_tables.length > 0 &&
					!this.$store.state.reservation.age_tables_filled
				) {
					this.set_values_agetables(cc_settings.date_tables);
				}
			});
		},

		get_payment_providers() {
			this.loading.administration = true;

			let payments = new class_payments(this.$store);
			payments.set_endpoint("payments/providers");
			payments.get();
		},

		set_filters(filters) {
			filters.forEach(filter => {
				this.$store.state.settings.filter[filter.meta.key] = filter;
				this.$store.state.settings.filter[filter.meta.key].value = null;
			});
		},

		set_values_agetables(age_tables) {

			let age = 0;
			let date = "";
			let total = 0;

			age_tables.forEach(age_table => {
				
				if(age_table.amount == null){
					age_table.amount = 0 ;
				}

				date = this.$moment(age_table.birthdate);
				age = this.$moment().diff(date, "years");

				let reservation_age_table = this.$store.state.reservation.age_tables.filter(
					table => {
						if (table.age_to) {
							return age >= table.age_from && age <= table.age_to;
						} else {
							return age >= table.age_from;
						}
					}
				);

				reservation_age_table[0].amount =
					reservation_age_table[0].amount + age_table.amount;

				total = total + age_table.amount;

			});

			this.$store.state.reservation.persons = total;
			this.$store.state.reservation.age_tables_filled = true;
		}
	}
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.cc_icon svg {
	width: 25px;
	max-height: 25px;
}
</style>
