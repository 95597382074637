<template>
	<div class="d-flex flex-wrap align-center justify-center search_view">
		<div class="cc_search_component">
			<Search view="search" />
		</div>

		<v-btn
			large
			depressed
			:color="$variables.colors.cyan"
			@click="search_and_book()"
			class="white--text"
			:block="$vuetify.breakpoint.smAndDown"
		>{{ $t("searchAndBook") }}</v-btn>
	</div>
</template>

<script>
import Search from "@/components/Search";
import { mapState } from "vuex";

export default {
	components: {
		Search,
	},

	computed: {
		...mapState(["accommodation", "settings"]),
	},

	created() {
		this.$store.state.accommodation = {};

		if ("gtm_id" in cc_settings && cc_settings.gtm_id ) {
			window.dataLayer.push({
				page: "search",
				action: "onload",
			});
		}
	},

	methods: {
		search_and_book() {
			if (this.accommodation.id && this.settings.filter_accommodation) {
				this.$router.push({
					path: "/accommodation/" + this.accommodation.id,
				});
			} else {
				this.$router.push({
					name: "list",
				});
			}
		},
	},
};
</script>

<style scoped>
/* .cc_search_component {
	min-width: 900px;
	margin-right: 24px;
}

@media (max-width: 960px) {
	.cc_search_component {
		min-width: inherit;
		width: 100%;
		margin-right: 0px;
	}
} */
</style>
