<template>

	<div v-bind:class="{ popup: !$store.state.settings.inline }">

		<div class="cc_page cc_loader" v-if="!payment_url">

			<v-progress-circular
			color="purple"
			indeterminate
			></v-progress-circular>

		</div>

		<div class="cc_page" v-else-if="payment_url && payment_url != 'none'">

			<v-progress-circular
			color="purple"
			indeterminate
			></v-progress-circular>

		</div>

		<div class="cc_page" v-else>

			<h1>{{ $t("thankYouMessage") }}</h1>

			<v-card class="mt-4">

				<v-card-text v-if="payment_url == 'none'">
					<icon name="done" class="done_icon" />
				</v-card-text>

				<v-divider class="mb-4"></v-divider>

				<v-card-actions>
					
					<v-btn
						large
						color="#01b5d8"
						to="/search"
						dark
						depressed
						class="ml-4 mb-4"
						v-if="payment_url == 'none'"
					>{{ $t("goBack") }}</v-btn>

					<!-- <v-btn
						large
						color="#01b5d8"
						:href="payment_url"
						dark
						depressed
						class="ml-4 mb-4"
						v-if="payment_url && payment_url != 'none'"
					>{{ $t("Pay") }}</v-btn> -->

				</v-card-actions>

			</v-card>

		</div>

	</div>

</template>

<style>

.done_icon svg {
	width: 50px;
	height: 50px;
	max-height: 50px;
}

.cc_loader {
	text-align: center;
}

</style>

<script>

/* eslint-disable no-console */

import icon from "@/api/icons.vue";
import axios from "axios";
import helpers from "@/api/helpers.js";

export default {
	components: {
		icon
	},
	data() {
		return {
			payment_url: null
		};
	},
	created() {

		let params = "";

		params = params + "&id=" + this.$store.state.reservation.id;

		// console.log(
		// 	helpers.get_api_endpoint(
		// 		this.$store.state,
		// 		"payments/providers/mollie",
		// 		params
		// 	)
		// );

		axios
			.get(
				helpers.get_old_api_endpoint(
					this.$store.state,
					"payments/providers/mollie",
					params
				)
			)
			.then((response) => {

				if (response.data.url && typeof response.data.url !== undefined) {

					this.payment_url = response.data.url ;

					window.location.href = this.payment_url ;

				} else {

					this.payment_url = "none";

				}
			})
			.catch(error => {

				let errorMessage = error.response.data.error.message;
				this.store.state.errors.push(
					`Error in get_administration: ${errorMessage}`
				);

				this.payment_url = "none";
				
			});
	}
};
</script>
