/* eslint-disable no-undef */
import Vue from "vue";
import VueGtm from "vue-gtm";
import App from "./App.vue";
import VueZoomer from "vue-zoomer";
import store from "./store";
import router from "./router";
import { i18n } from "./plugins/i18n.js";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import variables from "./api/variables";
import helpers from "./api/helpers";

Vue.use(VueZoomer);
Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, "$variables", { value: variables });
Object.defineProperty(Vue.prototype, "$helpers", { value: helpers });

if ("gtm_id" in cc_settings && cc_settings.gtm_id) {
    Vue.use(VueGtm, {
        id: cc_settings.gtm_id ? cc_settings.gtm_id : null,
        vueRouter: router,
        enabled: cc_settings.gtm_id ? true: false,
        defer: false,
        debug: true,
    });
}

// export const Vuezoomer = VueZoomer;

new Vue({
    store,
    router,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount("#cc_app");

// <!-- Google Tag Manager -->
// <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-P8DC9KK');</script>
// <!-- End Google Tag Manager -->

// <!-- Google Tag Manager (noscript) -->
// <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P8DC9KK"
// height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
// <!-- End Google Tag Manager (noscript) -->
