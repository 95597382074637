<template>
	<v-card outlined :loading="isLoading">
		<v-card-title>{{ $t("reservationHeader") }}</v-card-title>
		<v-divider class="mb-4"></v-divider>

		<v-card-text class="black--text">
			<div class="cc_reservation_contact">
				<p>{{ $t("reservationQuestions") }}</p>
				<p
					v-if="administration.meta.phone"
					class="cc_reservation_contact_info"
				>{{ $t("phone") + ": " + administration.meta.phone }}</p>
				<p
					v-if="administration.meta.email"
					class="cc_reservation_contact_info"
				>{{ $t("email") + ": " + administration.meta.email }}</p>
			</div>

			<v-divider class="mb-4"></v-divider>

			<p class="reservation_persons">
				<span v-for="person in reservation.age_tables" :key="person.id">
					{{ person.amount != 0 ? person.name + ": " + person.amount : null }}
					<br v-if="person.amount != 0" />
				</span>
			</p>

			<v-divider class="mb-4"></v-divider>

			<p class="cc_reservation_stay">
				<span>{{ $t("arrival") + ": " + show_date(reservation.arrival) }}</span>
				<br />
				<span>{{ $t("departure") + ": " + show_date(reservation.departure) }}</span>
			</p>

			<v-divider class="mb-4" v-if="this.reservation.total > 0"></v-divider>

			<p class="cc_reservation_discount-card" v-if="this.reservation.discount_card">
				{{ $t("discountCard") }}:
				<span>{{ discount_name }}</span>
			</p>

			<v-divider class="mb-4" v-if="this.reservation.discount_card"></v-divider>

			<div class="cc_reservation_place" v-if="reservation.place">
				<p>{{ $t("place") + ": " + available_places[reservation.place].name.toUpperCase() }}</p>
				<v-dialog v-model="dialog" width="500">
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" small>{{ $t("remove") }}</v-btn>
					</template>

					<v-card>
						<v-card-title class="headline lighten-2" primary-title>{{ $t("sureHeader") }}</v-card-title>
						<v-card-text class="mt-4">{{ $t("sureText") }}</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn text @click="dialog = false">{{ $t("cancel") }}</v-btn>
							<v-btn text @click="removePlace()">{{ $t("remove") }}</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>

			<v-row width="100%" class="mt-0 mb-4 pa-0 align-center d-none" v-if="!PromoInput">
				<v-col cols="12" class="py-0">
					<a href="javascript:void(0);" @click="showPromoInput">{{ $t("addPromoCode") }}</a>
				</v-col>
			</v-row>

			<v-row width="100%" class="mt-0 mb-4 pa-0 align-center d-none" v-if="PromoInput">
				<v-col cols="6" class="py-0">
					<v-text-field
						:label="$t('Promotion code')"
						width="100%"
						outlined
						hide-details
						dense
						v-model="promoCode"
						v-if="!reservation.promo_code"
					></v-text-field>
					<p v-else class="mb-0" style="font-weight: 500">{{ reservation.promo_code }}</p>
				</v-col>
				<v-col cols="5" class="py-0">
					<v-btn text small height="40px" @click="addPromoCode" v-if="!reservation.promo_code">Add</v-btn>
					<v-btn text small height="40px" @click="removePromoCode" v-else>{{ $t("remove") }}</v-btn>
				</v-col>
			</v-row>

			<v-divider class="mb-4" v-if="PromoInput"></v-divider>

			<v-divider class="mb-4" v-if="reservation.place"></v-divider>
			<transition-group name="list-complete" tag="div" v-if="reservation.rows">
				<div v-for="(option, index) in reservation_rows" :key="index" class="mb-2">
					<div
						:class="['cc_reservation_option', { cc_reservation_option__loading: loading.add_option }, { 'mb-0': hasDuplicatePrices && option.type === 'product_tax' }]"
						class="d-flex"
					>
						<div class="option_amount">{{ option.amount + "x" }}</div>
						<div class="option_description">{{ option.description }}</div>
						<v-spacer></v-spacer>
						<div class="option_price mr-0">{{$helpers.get_money(option.total, $store.state) }}</div>
					</div>

					<div
						class="option_included"
						v-if="hasDuplicatePrices(option)"
					>{{ $t("Included by") }} {{ getIncludedName(option) }}</div>
				</div>
			</transition-group>
			<p
				class="cc_reservation_price_total"
				v-if="this.reservation.total > 0"
			>{{ $t("total") + ": " + $helpers.get_money(reservation.total, $store.state) }}</p>
		</v-card-text>
		<v-alert v-if="!allow_booking" type="warning" class="mb-0">{{ error_message }}</v-alert>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import class_PriceCalculation from "@/api/price_calculation/class.price_calculation.js";
import { Price } from "@/mixins/Price.js";

//Components
import Loader from "@/components/Loader.vue";

export default {
	name: "Reservation",

	components: {
		Loader
	},

	mixins: [Price],

	computed: {
		...mapState([
			"administration",
			"reservation",
			"loading",
			"accommodation",
			"available_places"
		]),

		reservation_rows() {
			let rows = this.reservation.rows;
			return rows.concat(this.reservation.option_rows);
		},

		discount_name() {
			//--> Shows discountcard name. (Not promo code)
			if (this.reservation.discount_card) {
				let card = this.administration.cards.find(card => {
					return card.id === this.reservation.discount_card;
				});

				return card.name;
			}
		},

		error_message() {
			if (
				this.accommodation.meta.hasOwnProperty("duration_max") &&
				this.accommodation.meta.duration_max
			) {
				let arrival = this.$moment(this.reservation.arrival);
				let departure = this.$moment(this.reservation.departure);
				let duration = departure.diff(arrival, "days");

				if (duration > this.accommodation.meta.duration_max) {
					return `Maximale verblijfsduur overschreden, maximale verblijfsduur is ${this.accommodation.meta.duration_max} dagen.`;
				}
			}

			if (!this.reservation.arrival && !this.reservation.departure) {
				return "Please select when you want to come and leave, before you can proceed!";
			}

			return this.$t("errorAccommodation");
		},

		isLoading() {
			if (
				this.loading.options ||
				this.loading.recalculate_price ||
				this.loading.add_option
			) {
				return true;
			}

			return false;
		}
	},

	data() {
		return {
			dialog: false,
			promoCode: null,
			PromoInput: false
		};
	},

	created() {
		if (this.accommodation.id) {
			if (this.check_selection()) {
				this.loader = true;
				this.calculate_price();
			}
		}
	},

	methods: {
		show_date: function(date) {
			return this.$moment(date).format("DD-MM-YYYY");
		},

		removePlace() {
			this.$store.commit("set_selected_place", null);
			this.dialog = false;
		},

		addPromoCode() {
			this.$store.commit("set_promo_code", this.promoCode);
		},
		removePromoCode() {
			this.promoCode = null;
			this.$store.commit("set_promo_code", null);
		},

		showPromoInput() {
			this.PromoInput = true;
		},

		hasDuplicatePrices(option) {
			return option.data.hasOwnProperty("price_id");
		},

		getIncludedName(row) {
			let price_id = row.data.price_id;
			let price = this.reservation.rows.filter(row => {
				return price_id == row.type_id;
			});
			return price[0].description;
		}
	}
};
</script>

<style lang="scss" scoped>
.list-complete-item {
	transition: all 1s;
	display: inline-block;
	margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
	opacity: 0;
	transform: translateX(30px);
}
.list-complete-leave-active {
	position: absolute;
}

.reservation_loading {
	opacity: 0.5;
}
</style>
