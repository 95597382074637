import axios from "axios";
import helpers from "../../api/helpers.js";

class Payments {
    constructor(store) {
        this.store = store;
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    get() {
        let params = "";
        // axios.get(helpers.get_api_endpoint(this.store.state, this.endpoint, params))
        axios({
            method: "get",
            url: helpers.get_old_api_endpoint(this.store.state, this.endpoint, params),
        })
            .then((response) => {
                // console.log(response);
                this.store.commit("set_payment_icons", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export default Payments;
