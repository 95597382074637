import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store.js";
// import data from './messages.json'

Vue.use(VueI18n);

const messages = {
    en: require("@/locale/en.json"),
    nl: require("@/locale/nl.json"),
    de: require("@/locale/de.json"),
    it: require("@/locale/it.json"),
    fr: require("@/locale/fr.json"),
};

export const i18n = new VueI18n({
    locale: store.state.settings.language, //Locale
    fallbackLocale: "en", //set fallback locale,
    messages,
});
