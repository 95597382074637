<template>
	<nav class="cc_header">
		<v-app-bar app hide-on-scroll elevation="0" class="cc_top_app_bar">
			<v-btn
				href="javascript:window.history.back();"
				color="transparent"
				elevation="0"
				icon
				class="ml-0"
			>
				<icon name="arrow_left" />
			</v-btn>

			<v-spacer />

			<icon
				v-if="administration.meta.languages.length > 1"
				:name="'flag_' + $i18n.locale"
				class="cc_header_flag"
			/>

			<v-select
				v-if="administration.meta.languages.length > 1"
				:items="available_translations"
				:item-text="$i18n.locale"
				item-value="value"
				v-model="selected_language"
				:hide-details="true"
				class="cc_languageSelect"
			></v-select>
		</v-app-bar>
	</nav>
</template>

<script>
import { mapState } from "vuex";
import { Translation } from "@/mixins/Translation.js";

import icon from "@/api/icons.vue";

export default {
	name: "Header",

	components: {
		icon
	},

	mixins: [Translation],

	computed: {
		...mapState(["administration", "settings"])
	},

};
</script>

<style>
.cc_languageSelect.v-text-field {
	max-width: 170px;
}

.cc_languageSelect.v-text-field > .v-input__control > .v-input__slot:before {
	border-style: none;
}
.cc_languageSelect.v-text-field > .v-input__control > .v-input__slot:after {
	border-style: none;
}

.cc_top_app_bar {
	border-bottom: 1px solid #cccccc !important;
}
</style>
