<template>
	<v-snackbar v-model="open_snackbar" :color="$variables.colors.red" :timeout="10000">{{error}}</v-snackbar>
</template>
<script>
export default {
	props: ["error"],

	data() {
		return {
			open_snackbar: true
		};
	},

	created() {
		console.log("Snackbar error: ", this.error);
	}
};
</script>