<template>

	<div class="cc_page">

		<v-card class="mt-6">

			<v-card-title class="d-flex justify-center">{{ $t("pending") }}</v-card-title>

			<v-divider class="mb-4"></v-divider>

			<v-card-text style="position: relative" height="100px" class="pending__loader">
				<Loader />
			</v-card-text>
			
		</v-card>

	</div>

</template>

<script>
import Loader from "@/components/Loader.vue";
export default {
	components: {
		Loader
	}
};
</script>

<style lang="scss" scoped>
.pending__loader {
	height: 100px;
}
</style>