import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import moment from "moment";
import { extendMoment } from "moment-range";
import helpers from "./api/helpers.js";

Vue.use(Vuex);

extendMoment(moment);

/**
 * Save specific data to the session storage.
 * @reducer function lets you decide which.
 */
const vuexLocal = new VuexPersistence({
    key: "store",
    storage: window.sessionStorage,
    reducer: (state) => ({
        administration: state.administration,
        reservation: state.reservation,
        filter_accommodations: state.filter_accommodations,
        accommodation: state.accommodation,
        settings: state.settings,
        available_places: state.available_places,
        filtered_accommodations: state.filtered_accommodations,
        accommodations: state.accommodations,
    }),
});

export default new Vuex.Store({
    state: {
        settings: {
            //hash mode includes hashtag in url,
            //history mode removes hash from url
            router_mode: "hash",
            engine_type: "camping", //'camping' or 'ota' or 'backend'
            public_key: "NK0NeOmO3ROvdpas3G9KEvBp", // -->  leadingsdemo
            channel_id: 1, // determines source of reservation
            source: "online",
            view: "search",
            inline: false,
            // accommodation_select_hide: false,
            filter_rentorcamp: true,
            filter_accommodation: true,
            accommodations_per_page: 10,
            birthDate: true,
            api_path: "https://camping.care/api/v2",
            language: navigator.language.substr(0, 2),
            loader: "https://storage.googleapis.com/campingcare-static/images/logo_loader.svg",
            favicon: "https://storage.googleapis.com/campingcare-static/images/icons/favicon.ico",
            filters: [],
            filter: {
                // active: false,
                acco_type: null,
                park_id: null,
                country: null,
                discount_card: null,
                accommodation: null,
            },
        },
        administration: {
            id: 77,
        },
        arrangements: [],
        available_places: [],
        accommodation: null,
        accommodations: null,
        filtered_accommodations: null,
        price_calculation: null,
        reservation: {
            arrival: moment().format("YYYY-MM-DD"),
            departure: moment()
                .add(7, "days")
                .format("YYYY-MM-DD"),
            age_tables: [],
            total: 0,
            discount_card: "",
            persons: 2,
            option_rows: [],
            promo_code: "",
            arrangement: null,
            meta: {
                gender: null,
                first_name: "",
                last_name: "",
                zipcode: "",
                city: "",
                address: "",
                address_number: "",
                country: navigator.language.substr(0, 2).toUpperCase(),
                birth: {
                    birth_day: "",
                    birth_month: "",
                    birth_year: "",
                },
                email: "",
                phone: "",
                note_guest: "",
            },
        },
        created_reservation: {},
        prices: [],
        loading: {
            accommodations: false,
            accommodation: false,
            options: false,
            administration: false,
            places: true,
            recalculate_price: false,
            prices: false,
            pending: false,
            add_option: false,
            arrangements: false,
            images: false,
        },
        errors: [],
        parks: [],
        filter_accommodations: [],
        currency: {
            EUR: "€",
            GBP: "£",
            CHF: "CHF",
            USD: "$",
            SEK: "kr",
            NOK: "kr",
            CZK: "Kč",
            DKK: "kr",
            PLZ: "zł",
            SVK: "€",
            SVN: "€",
            HRV: "€",
            HUF: "Ft",
            CAD: "$",
            ZAR: "R",
            AUD: "$",
        },
        completed: false,
        payment_methods: [],
        payment_icons: [],
        dialogs: {
            persons: false,
        },
    },
    mutations: {
        set_administration(state, data) {
            state.administration = data.administration;
            state.administration.meta.languages = data.administration.meta.park_languages ;
            state.administration.meta.park_languages = data.languages;

            /**
             * Inserts sections into reservation
             */
            // let reservationCache = JSON.parse(sessionStorage.getItem("store"));

            if (state.reservation.age_tables.length <= 0) {
                let reservation_age_tables;
                if (data.administration.age_tables.length > 0) {
                    reservation_age_tables = data.administration.age_tables.map((element, index) => {
                        if (cc_settings.hasOwnProperty("date_tables") && cc_settings.date_tables.length > 0) {
                            return {
                                id: element.id,
                                name: element.name,
                                amount: 0,
                                priority: element.priority,
                                age_from: element.age_from,
                                age_to: element.age_to,
                            };
                        } else if (element.priority) {
                            return {
                                id: element.id,
                                name: element.name,
                                amount: element.priority == 1 ? 2 : 0,
                                priority: element.priority,
                                age_from: element.age_from,
                                age_to: element.age_to,
                            };
                        }
                        return {
                            id: element.id,
                            name: element.name,
                            amount: index === 1 ? 2 : 0,
                            priority: element.priority,
                            age_from: element.age_from,
                            age_to: element.age_to,
                        };
                    });
                } else {
                    reservation_age_tables = {
                        name: "persons",
                        amount: 2,
                    };
                }

                // state.reservation.age_tables = state.reservation.age_tables.length <= 0 ? reservation_age_tables : reservationCache.reservation.age_tables;
                state.reservation.age_tables = reservation_age_tables;
            }

            /*****************/

            state.loading.administration = false;
        },

        set_accommodations(state, accommodations) {
            state.loading.accommodations = true;

            accommodations.forEach((accommodation) => {
                if (!accommodation.meta.hasOwnProperty("thumb")) {
                    accommodation.meta.thumb = "https://storage.googleapis.com/campingcare-static/images/default_thumb.jpg";
                }
            });

            state.accommodations = accommodations;
            state.loading.accommodations = false;

            /** Maybe improve speed here, long list of acco's may take long **/
            accommodations.map((accommodation) => {
                if (accommodation.media.length > 0) {
                    state.loading.images = false;
                    return;
                }
            });

            // if (state.loading.images) {
            //     state.loading.images = false;
            // }
        },

        set_accommodation(state, accommodation) {
            state.accommodation = accommodation;

            if (accommodation.id > 0) {
                if (accommodation.meta.dates_without_stock && typeof accommodation.meta.dates_without_stock === "string") {
                    let disabled_dates = accommodation.meta.dates_without_stock ? accommodation.meta.dates_without_stock : [];
                    state.accommodation.meta.dates_without_stock = disabled_dates;
                }

                let parsedArrivalDates = accommodation.meta.arrival_days ? accommodation.meta.arrival_days : null;
                accommodation.arrival_days = parsedArrivalDates ? helpers.setToCorrectDays(parsedArrivalDates) : { exceptions: [] };

                parsedArrivalDates = accommodation.meta.departure_days ? accommodation.meta.departure_days : null;
                accommodation.departure_days = parsedArrivalDates ? helpers.setToCorrectDays(parsedArrivalDates) : { exceptions: [] };

                let datepickerSettings = [
                    {
                        dot: "green",
                        dates: [],
                        popover: {
                            label: "Arrival day",
                        },
                    },
                    {
                        dot: "orange",
                        dates: [],
                        popover: {
                            label: "Departure day",
                        },
                    },
                ];
                state.accommodation.datepickerSettings = datepickerSettings;
                state.reservation.accommodation_id = accommodation.id;
                state.loading.accommodation = false;
            }
        },

        clear_accommodation(state) {
            state.accommodation = {};
        },

        dates_change(state, dates) {
            state.reservation.arrival = moment(dates.start).format("YYYY-MM-DD");
            state.reservation.departure = moment(dates.end).format("YYYY-MM-DD");
        },

        empty_dates(state) {
            state.reservation.arrival = null;
            state.reservation.departure = null;
        },

        set_filter(state, filter) {
            state.settings.filter = filter;
        },

        set_persons(state, data) {
            state.reservation.age_tables = data.age_tables;
            state.reservation.persons = data.total_persons;
        },

        set_parks(state, parks) {
            state.parks = parks;
        },

        set_price_calculation(state, price_calculation) {
            //Add options to reservation
            state.accommodation.options = price_calculation.available_option_rows;
            state.reservation.rows = price_calculation.rows;
            state.reservation.option_rows = price_calculation.option_rows;
            state.reservation.total = price_calculation.total;
            state.reservation.calculation_draft_id = price_calculation.calculation_draft_id;
            state.reservation.calculation_id = price_calculation.calculation_id;
            state.price_calculation = price_calculation;
            state.loading.options = false;
            state.loading.recalculate_price = false;
        },

        change_option_value(state, data) {
            state.accommodation.options.find((x) => x.id === data.option.id).selected_amount = data.value;
            setTimeout(() => {
                state.loading.add_option = false;
            }, 1000);
        },

        // set_filter_accommodations(state, data) {
        //     state.filter_accommodations = data;
        // },

        set_view(state, view) {
            state.settings.view = view;
        },

        set_inline(state, inline) {
            state.settings.inline = inline;
        },

        set_personal_information(state, data) {
            state.reservation.meta = data;
        },

        change_language(state, language) {
            state.settings.language = language;
        },

        set_finished(state, completed) {
            setTimeout(() => {
                state.loading.pending = false;
            }, 1000);
            state.completed = completed;
        },

        set_created_reservation(state, data) {
            state.reservation.id = data.id;
            // state.created_reservation = data;
        },

        set_available_places(state, places) {
            state.available_places = places;
            state.loading.places = false;
        },

        set_selected_place(state, place) {
            state.reservation.place = place;
        },

        set_filtered_accommodations(state, accommodations) {
            state.filtered_accommodations = accommodations;
        },

        empty_filter(state, filter) {
            state.settings.filter[filter.meta.key].value = null;
        },

        set_promo_code(state, code) {
            state.reservation.promo_code = code;
        },

        set_discount_card(state, card) {
            state.reservation.discount_card = card;
        },

        set_arrangements(state, arrangements) {
            if (state.accommodation) {
                state.accommodation.arrangements = arrangements;
            }
            state.arrangements = arrangements;
            state.loading.arrangements = false;
        },

        select_arrangement(state, arrangement) {
            state.reservation.arrival = arrangement.start_date;
            state.reservation.departure = arrangement.end_date;
            state.reservation.arrangement = arrangement;
        },

        set_payment_icons(state, payments) {
            let icons = [];

            state.payment_methods = payments;
            payments.forEach((payment) => {
                if (payment.methods) {
                    payment.methods.forEach((method) => {
                        if (icons.length > 0) {
                            let is_unique = icons.find((icon) => {
                                icon.key === method.key;
                            });

                            if (!is_unique) {
                                icons.push(method);
                            }
                        } else {
                            icons.push(method);
                        }
                    });
                }
            });

            state.payment_icons = icons;
        },

        set_persons_dialog(state, value) {
            state.dialogs.persons = value;
        },
    },

    actions: {
        day_is_valid({ day, dates }) {
            let is_valid = false;
            let dayNumber = moment(day).day();

            let range = "";
            if (dates) {
                dates = dates ? JSON.parse(dates) : null;

                if (dates.exceptions === []) {
                    dates.exceptions = null;
                }
                if (dates.exceptions !== null) {
                    dates.exceptions.forEach((exception) => {
                        range = moment().range(exception.start, exception.end);
                        if (range.contains(this.moment(day))) {
                            if (exception.days.includes(dayNumber)) {
                                is_valid = true;
                            }
                        }
                    });
                }

                if (dates.default.includes(dayNumber)) {
                    is_valid = true;
                }
                return is_valid;
            }
            return true;
        },
    },

    plugins: [vuexLocal.plugin],
});
