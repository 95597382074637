<template>
	<div class="cc_search_container">
		<div class="cc_toolbar d-flex flex-wrap align-center">
			<v-dialog v-model="dialog_calendar" :width="width" :fullscreen="show_fullscreen()">
				<!-- ref="calendarDialog" -->
				<template v-slot:activator="{ on }">
					<v-btn large outlined class="mr-4 d-flex align-center justify-center cc_date_button" v-on="on">
						<icon name="date" class="mr-2" />

						<div class="cc_p cc_arrival_departure">
							<div class="cc_arrival">{{ show_arrival(reservation.arrival) }}</div>

							<div class="cc_small_arrow">
								<icon name="arrow_right" />
							</div>

							<div class="cc_departure">{{ show_departure(reservation.departure) }}</div>

							<div class="cc_days">{{ calendar_days_text }}</div>
						</div>
					</v-btn>
				</template>

				<v-card>
					<v-toolbar class="elevation-0 mb-2">
						<div class="cc_button_icon" style="cursor:initial;">
							<icon name="date" />
						</div>

						<v-toolbar-title>{{ $t("determineStay") }}</v-toolbar-title>
						<v-spacer></v-spacer>

						<div class="cc_button_icon" v-on:click="dialog_calendar = false">
							<icon name="close" />
						</div>
					</v-toolbar>

					<v-card-text>
						<Calendar :inline="true" vertical="false" />
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-spacer v-if="$vuetify.breakpoint.smAndUp" />

			<v-dialog v-model="dialog_persons" :width="width" :fullscreen="show_fullscreen()">
				<template v-slot:activator="{ on }">
					<v-btn
						large
						outlined
						:class="[{'mr-4': !hideFilter}, 'd-flex', 'justify-start', 'cc_persons_button']"
						v-on="on"
					>
						<icon name="users" class="mr-2" />

						<div class="cc_p">{{ show_persons(reservation.persons) }}</div>
					</v-btn>
				</template>

				<v-card>
					<v-toolbar class="elevation-0">
						<div class="cc_button_icon" style="cursor:initial;">
							<icon name="users" />
						</div>

						<v-toolbar-title>{{ $t("persons") }}</v-toolbar-title>
						<v-spacer></v-spacer>

						<div class="cc_button_icon" v-on:click="dialog_persons = false">
							<icon name="close" />
						</div>
					</v-toolbar>

					<v-divider class="mb-4"></v-divider>

					<v-card-text>
						<Persons v-if="!this.loading.administration" />
						<Loader v-else />
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-spacer v-if="!hideFilter" />

			<v-dialog
				v-model="dialog_filter"
				:width="width"
				:fullscreen="show_fullscreen()"
				v-if="!hideFilter"
			>
				<template v-slot:activator="{ on }">
					<v-btn outlined large v-on="on" class="cc_filter_button">
						<icon name="filter" />
					</v-btn>
				</template>

				<v-card>
					<v-toolbar class="elevation-0">
						<div class="cc_button_icon" style="cursor:initial;">
							<icon name="filter" />
						</div>

						<v-toolbar-title>{{ $t("filter") }}</v-toolbar-title>
						<v-spacer></v-spacer>

						<div class="cc_button_icon" v-on:click="dialog_filter = false">
							<icon name="close" />
						</div>
					</v-toolbar>

					<v-divider class="mb-4"></v-divider>

					<v-card-text>
						<AdvancedFilter />
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
// import moment from "moment";
import { mapState } from "vuex";

//components
import icon from "@/api/icons.vue";
import Calendar from "@/components/Calendar.vue";
import Persons from "@/components/Persons.vue";
import AdvancedFilter from "@/components/Filter.vue";
import Loader from "@/components/Loader.vue";

export default {
	components: {
		icon,
		Calendar,
		Persons,
		AdvancedFilter,
		Loader
	},
	computed: {
		...mapState(["reservation", "settings", "loading", "administration"]),

		hideFilter() {
			if (
				this.$route.name === "search" ||
				this.$route.name === "list" ||
				this.$route.name === "map"
			) {
				if (
					!this.settings.inline &&
					!this.settings.filter_rentorcamp &&
					!this.settings.filter_accommodations &&
					this.settings.engine_type !== "ota" &&
					!this.administration.meta.discount_card_enabled
				) {
					return true;
				}
				return false;
			} else {
				return true;
			}
		},

		width() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				return "500px";
			}

			if (this.$vuetify.breakpoint.smAndDown) {
				return "100%";
			}

			return "100%";
		}
	},

	data() {
		return {
			calendar_days_text: "(3 days)",
			dialog_calendar: false,
			dialog_persons: false,
			dialog_filter: false
		};
	},

	watch: {
		dialog_persons(value) {
			this.$store.commit("set_persons_dialog", value);
		}
	},

	methods: {
		show_persons: function(persons) {
			let total = persons ? persons : 2;
			if (total == 1) {
				return `${total} ${this.$t("person")}`;
			} else {
				return `${total} ${this.$t("persons")}`;
			}
		},

		show_arrival: function(datestring) {
			if (datestring) {
				return this.$moment(datestring).format("DD-MM");
			} else {
				return "Arrival";
			}
		},

		show_departure: function(datestring) {
			if (datestring) {
				let arrival = this.reservation.arrival;
				let a = this.$moment(datestring);
				let b = this.$moment(arrival);
				let diff = a.diff(b, "days"); // =1

				this.calendar_days_text = `(${diff} ${this.$t("nights")})`;

				return this.$moment(datestring).format("DD-MM");
			} else {
				this.calendar_days_text = "";
				return "Departure";
			}
		},

		show_fullscreen() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				return false;
			}

			return true;
		}
	}
};
</script>

<style lang="scss">
.cc_toolbar {
	width: 100%;
}

.cc_date_button {
	width: 43%;
	border-color: #cccccc;
}

.cc_persons_button {
	width: 43%;
	border-color: #cccccc;
}

.cc_popup span {
	width: 25px;
}

.cc_filter_button {
	border-color: #cccccc;
}

@media (max-width: 960px) {
	.cc_date_button {
		width: 100%;
	}

	.cc_persons_button {
		width: 65%;
		margin-top: 16px;
	}

	.cc_filter_button {
		width: 30%;
		margin-top: 16px;
	}
}
</style>
