<template>
	<Loader v-if="loading.accommodation" />

	<div class="cc_accommodation_page" v-else>
		<div class="cc_page cc_accommodation_container">
			<h1 class="pb-6 pt-2">{{ accommodation.translations[settings.language].name }}</h1>

			<v-card outlined>
				<div class="images">
					<v-img
						:src="accommodation.images[0] ? accommodation.images[0] : accommodation.meta.thumb"
						width="100%"
						height="350"
						class="hidden-sm-and-down"
					/>

					<div class="cc_top_images pb-6">
						<vue-picture-swipe
							v-if="accommodation_images.length > 0"
							:options="{ history: true }"
							ref="pictureSwipe"
							:items="accommodation_images"
						></vue-picture-swipe>
					</div>
				</div>
			</v-card>

			<Search class="py-6" />

			<div class="information_wrapper">
				<v-tabs
					v-model="contentTab"
					:background-color="$variables.colors.lightGray"
					:color="$variables.colors.black"
					grow
					class="cc_accommodation_left"
				>
					<v-tab>{{ $t("information") }}</v-tab>
					<v-tab v-if="accommodation.arrangements.length > 0">{{ $t("arrangements") }}</v-tab>

					<v-tabs-items v-model="contentTab" width="100%" class="mt-2">
						<v-tab-item width="100%">
							<v-card outlined class="mb-4" v-if="has_services">
								<v-tabs
									v-model="tab"
									:background-color="$variables.colors.white"
									:color="$variables.colors.black"
									grow
								>
									<v-tab v-if="services(1).length > 0">{{ $t("serviceGeneral") }}</v-tab>
									<v-tab v-if="services(2).length > 0">{{ $t("serviceIncluded") }}</v-tab>
									<v-tab v-if="services(99).length > 0">{{ $t("serviceExcluded") }}</v-tab>

									<v-tabs-items v-model="tab">
										<v-tab-item v-if="services(1).length > 0">
											<v-card-text>
												<div class="general-services__services-holder cc_content">
													<p
														v-for="service in services(1)"
														:key="service.service_id"
														class="general-services general-services__service"
													>
														<icon name="tick" class="icon" />
														{{ service.name }}
													</p>
												</div>
											</v-card-text>
										</v-tab-item>

										<v-tab-item v-if="services(2).length > 0">
											<v-card-text>
												<div class="general-services__services-holder cc_content">
													<p
														v-for="service in services(2)"
														:key="service.service_id"
														class="general-services general-services__service"
													>
														<icon name="tick" class="icon" />
														{{ service.name }}
													</p>
												</div>
											</v-card-text>
										</v-tab-item>

										<v-tab-item v-if="services(99).length > 0">
											<v-card-text>
												<div class="general-services__services-holder cc_content">
													<p
														v-for="service in services(99)"
														:key="service.service_id"
														class="general-services general-services__service"
													>
														<icon name="cross" class="icon icon--red" />
														{{ service.name }}
													</p>
												</div>
											</v-card-text>
										</v-tab-item>
									</v-tabs-items>
								</v-tabs>
							</v-card>

							<v-card outlined class="mb-4">
								<v-card-title class="cc_title">{{ $t("moreInformation") }}</v-card-title>
								<v-divider class="mb-4"></v-divider>

								<v-card-text
									class="pt-0"
									v-html="mdParser.render(accommodation.translations[settings.language].description)"
								></v-card-text>
							</v-card>

							<v-card outlined class="mb-4" v-if="payment_terms">
								<v-card-title class="cc_title">{{ $t("paymentterms") }}</v-card-title>
								<v-divider class="mb-4"></v-divider>
								<v-card-text
									width="100%"
									class="pt-0"
									v-for="(term, index) in payment_terms"
									:key="index"
								>{{ createPaymentString(term) }}</v-card-text>
							</v-card>

							<v-card outlined class="mb-4" v-if="cancelation_terms">
								<v-card-title class="cc_title">{{ $t("cancelationterms") }}</v-card-title>
								<v-divider class="mb-4"></v-divider>
								<v-card-text
									width="100%"
									class="pt-0"
									v-for="(term, index) in cancelation_terms"
									:key="index"
								>{{ createCancelationString(term) }}</v-card-text>
							</v-card>
							<!-- </div> -->
						</v-tab-item>

						<v-tab-item width="100%" v-if="accommodation.arrangements.length > 0">
							<Loader v-if="this.loading.arrangements" style="margin-top: 50px" />
							<v-card
								v-else
								outlined
								width="100%"
								class="mb-2"
								v-for="arrangement in accommodation.arrangements"
								:key="arrangement.id"
							>
								<v-row outlined align="center" class="px-2">
									<v-col cols="8" sm="4">
										<span style="font-weight: 600;">{{ arrangement.name }}</span>
										<span v-if="$vuetify.breakpoint.smAndDown">
											<br />
											{{ show_date(arrangement.start_date) + "/" + show_date(arrangement.end_date) }}
											<br />
											{{ $helpers.get_money(arrangement.price, $store.state) }}
										</span>
									</v-col>
									<v-col
										cols="4"
										v-if="$vuetify.breakpoint.mdAndUp"
									>{{ show_date(arrangement.start_date) + "/" + show_date(arrangement.end_date) }}</v-col>
									<v-col
										cols="2"
										v-if="$vuetify.breakpoint.mdAndUp"
									>{{ $helpers.get_money(arrangement.price, $store.state) }}</v-col>
									<v-spacer></v-spacer>
									<v-col cols="4" sm="2">
										<v-btn width="100%" @click="select_arrangement(arrangement)">{{ $t("book") }}</v-btn>
									</v-col>
								</v-row>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-tabs>

				<div class="cc_accommodation_right">
					<Reservation ref="cp_reservation" />

					<v-card-actions class="pa-0 my-4 d-flex flex-column-reverse">
						<v-btn
							class="mb-4 white--text"
							:disabled="!allow_booking"
							large
							block
							depressed
							:color="$variables.colors.cyan"
							:to="{ name: 'book' }"
						>{{ $t("book") }}</v-btn>
					</v-card-actions>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Remarkable } from "remarkable";
import { mapState } from "vuex";
import VuePictureSwipe from "vue-picture-swipe";
import classAccommodation from "@/api/accommodations/class.accommodation";
import classArrangements from "@/api/arrangements/class.arrangements";

//components
import icon from "@/api/icons.vue";
import Search from "@/components/Search";
import Reservation from "@/components/Reservation";
import Loader from "@/components/Loader.vue";
import { Price } from "@/mixins/Price.js";

export default {
	components: {
		icon,
		Search,
		VuePictureSwipe,
		Reservation,
		Loader
	},

	mixins: [Price],

	computed: {
		...mapState([
			// "accommodations",
			"accommodation",
			"administration",
			"loading",
			"settings",
			"reservation"
		]),

		payment_terms() {
			if ("payment_terms" in this.accommodation.meta) {
				let data = this.accommodation.meta.payment_terms ;
				return data.length ? data : null;
			}

			return null;
		},

		cancelation_terms() {
			if ("cancelation_terms" in this.accommodation.meta) {
				let data = this.accommodation.meta.cancelation_terms ;
				return data.length ? data : null;
			}

			return null;
		},

		has_services() {
			let has_service = false;
			Object.keys(this.accommodation.services).forEach(key => {
				if (this.accommodation.services[key].length > 0) {
					has_service = true;
				}
			});

			return has_service;
		},

        accommodation_images(){
            const images = this.accommodation.images.filter(image => {
                if(image.type_id === this.accommodation.id) return image;
            })

            return images;
        }
	},

	data() {
		return {
			accommodation_id: this.$route.params.accommodation_id,
			tab: null,
			contentTab: null,
			mdParser: new Remarkable()
			// prevRoute: null,
		};
	},

	methods: {
		services(service_array) {
			let parsedServices = this.accommodation.services[
				service_array
			].filter(service => {
				return service.language === this.$i18n.locale;
			});

			return parsedServices;
		},

		show_date: function(datestring) {
			return this.$moment(datestring).format("DD-MM-YYYY");
		},

		createPaymentString(paycondition) {
			let data = {
				amount:
					paycondition.term_type === "percentage"
						? `${paycondition.term_amount}%`
						: this.$helpers.get_money(
								paycondition.term_amount,
								this.$store.state
						  ),
				days: paycondition.term_days
			};

			if (paycondition.term_when === "after_booking") {
				return this.$t("paymentStringAfterBooking", data);
			}

			if (paycondition.term_when === "before_arrival") {
				return this.$t("paymentStringBeforeArrival", data);
			}
		},

		createCancelationString(cancelcondition) {
			let data = {
				amount:
					cancelcondition.term_type === "percentage"
						? `${cancelcondition.term_amount}%`
						: this.$helpers.get_money(
								cancelcondition.term_amount,
								this.$store.state
						  ),
				days: cancelcondition.term_days
			};

			return this.$t("cancellationString", data);
		},

		get_arrangements() {
			this.loading.arrangements = true;
			let arrangements = new classArrangements(this.$store);
			arrangements.set_endpoint("arrangements");
			arrangements.set_accommodation_id(this.accommodation.id);
			arrangements.get();
		},

		select_arrangement(arrangement) {
			this.$store.commit("select_arrangement", arrangement);
			this.calculate_price();

			this.$router.push({ name: "book" });
		}
	},

	created() {
		// this.prevRoute = this.$route.meta.props.prevUrl;

		this.$store.state.loading.accommodation = true;
		classAccommodation.set_accommodation_id(this.accommodation_id);
		classAccommodation.get_translations(true);
		classAccommodation.set_meta(true);
		classAccommodation.set_services(true);
		classAccommodation.set_arrangements(true);

		classAccommodation.get().then(response => {
			if (response.data.status === "active") {
				if (this.check_selection()) {
					this.loader = true;
					this.calculate_price();
				}

				if (!this.arrangements) {
					this.get_arrangements();
				}
			} else {
				alert("This accommodation is disabled! Redirect to overview");
				this.$router.push({ name: "list" });
			}
		});
	}

	// beforeRouteEnter(to, from, next) {
	// 	to.meta.props = {
	// 		prevUrl: from.path
	// 	};
	// 	next(vm => {
	// 		vm.prevRoute = from;
	// 	});
	// }
};
</script>

<style lang="scss" scoped>
.v-tabs {
	.hideTabs {
		display: none !important;
	}

	.tabHidden {
		margin-top: -56px !important;
	}
}
</style>
