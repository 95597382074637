import axios from "axios";
import helpers from "../../api/helpers.js";

class administrations {
    constructor() {
        this.store = "";
        this.endpoint = "administrations";
    }

    set_store(store) {
        this.store = store;
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    // get_prices(){

    // 	let cc_state = this.store.state ;

    // 	cc_state.accommodations.forEach(function (accommodation, key) {

    // 		//this.$store.commit('dates_change', dates)
    // 		console.log('HELPPPP');
    // 		console.log(accommodation.id);

    // 	});

    // }

    get() {
        // console.log('this.store');
        // console.log(this.store);

        let cc_state = this.store.state;
        let params = "";

        axios
            .get(helpers.get_api_endpoint(cc_state, this.endpoint, params))
            .then((response) => {
                let parks = response.data;

                this.store.commit("set_parks", parks);

                // this.accommodations = response.data ;
                // this.store.commit('set_accommodations', response.data) ;

                // console.log(new_accommodation_list);
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export default new administrations();
