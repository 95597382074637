import axios from "axios";
import helpers from "@/api/helpers.js";
import languagesJSON from "@/assets/languages.json";

class administration {
    constructor(admin_id, store) {
        this.admin_id = admin_id;
        this.store = store;
        this.fetch_meta = false;
        this.endpoint = "administrations/" + this.admin_id;
        //this.endpoint = "administrations/"+admin_id;
        // this.administration = {};
    }

    set_store(store) {
        this.store = store;
    }

    set_endpoint(endpoint) {
        this.endpoint = endpoint;
    }

    get_meta(){
        this.fetch_meta = true;
    }

    set_admin_id(id) {
        this.admin_id = id;
    }

    get_age_tables(value) {
        this.age_tables = value;
    }

    get_discount_cards(value) {
        this.discount_cards = value;
    }

    get() {
        let cc_state = this.store.state;
        let params = "";

        if (this.age_tables) {
            params = params + "&get_age_tables=" + this.age_tables;
        }

        if (this.discount_cards) {
            params = params + "&get_discount_cards=" + this.discount_cards;
        }

        if(this.fetch_meta){
            params = params + "&get_meta=true"
        }

        return axios
            .get(helpers.get_api_endpoint(cc_state, this.endpoint, params))
            .then((response) => {
                let administration = response.data;
                let park_languages = administration.meta.park_languages ;
                let languages = {};

                administration.meta.map_image = administration.meta.map_image ? administration.meta.map_image : {};
                administration.meta.map_data = administration.meta.map_data ? administration.meta.map_data : [];

                park_languages.map((item) => {
                    languages[item] = {
                        name: languagesJSON[item].name,
                        translations: languagesJSON[item].translations,
                        nativeName: languagesJSON[item].nativeName,
                        code: item,
                    };
                });

                this.store.commit("set_administration", {
                    administration,
                    languages,
                });
            })
            .catch((error) => {
                console.log(error.message);
                let errorMessage = error;
                this.store.state.errors.push(`Error in get_administration: ${errorMessage}`);
            });
    }
}

export default administration;
