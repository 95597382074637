<template>
	<!-- <div class="cc_map-holder"> -->
	<v-zoomer ref="vZoomer" :zoomed="true" pivot="cursor" :maxScale="7">
		<div class="map__data">
			<svg class="map_svg" />
		</div>
	</v-zoomer>
	<!-- </div> -->
</template>

<script>
import { SVG } from "@svgdotjs/svg.js";
import { mapState } from "vuex";

import class_accommodation from "@/api/accommodations/class.accommodation.js";

export default {
	computed: {
		...mapState([
			"accommodation",
			"available_places",
			"administration",
			"loading"
		])
	},

	watch: {
		available_places() {
			this.createMap();
		}
	},

	methods: {
		createMap() {
			let availablePlaces = Object.keys(this.available_places);

			let map = SVG(".map_svg")
				.addTo(".map__data")
				.clear()
				.size(
					this.administration.meta.map_image.width,
					this.administration.meta.map_image.height
				);

			map.viewbox(
				0,
				0,
				this.administration.meta.map_image.width,
				this.administration.meta.map_image.height
			);
			map.image(this.administration.meta.map_image.public_url);

			this.administration.meta.map_data.forEach(place => {
				if (availablePlaces.includes(place[4])) {
					let rect = map
						.rect(place[3], place[2])
						.move(place[1], place[0])
						.css({ opacity: "0.2" });

					if (!place[4] || !availablePlaces.includes(place[4])) {
						rect.fill("#f06").addClass(`is_booked`);
					} else {
						rect.fill("#008000")
							.attr("place_id", place[4])
							.addClass(`has_place place_${place[4]}`)
							.on("mousedown", () => {
								this.openPlace(place[4]);
								this.$parent.show_popup = true;
								this.$parent.place = place[4];
							});
					}
				}
			});
		},

		openPlace(place_id) {
			if (this.accommodation.id === undefined) {
				this.get_accommodation(
					this.available_places[place_id].accommodation_id
				);
			}
		},

		get_accommodation(accommodation_id) {
			this.loading.accommodation = true;

			class_accommodation.set_store(this.$store);
			class_accommodation.set_accommodation_id(accommodation_id);
			class_accommodation.set_admin_id(this.administration.id);
			class_accommodation.get_translations("true");
			class_accommodation.get();
		}
	},

	mounted() {
		if (this.$parent.isMobile) {
			this.$refs.vZoomer.scale = 3;
		}
		this.createMap();
		// this.$refs.vZoomer.zoomed = true;
		this.$refs.vZoomer.limitTranslation = false;
	}
};
</script>

<style lang="scss" >
.zoomer {
	height: 100%;
	svg,
	image {
		height: 100%;
		width: 100%;
	}
}
// .zoomer,
// svg,
// image {
// 	height: 100%;
// 	width: 100%;
// }

// svg {
//     width: 100%;
//     height: inherit;
// }
</style>
