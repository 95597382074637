import Vue from "vue";
import Router from "vue-router";
import store from "./store.js";

//components
import search from "./views/Search.vue";
import list from "./views/List.vue";
import book from "./views/Book.vue";
import accommodation from "./views/Accommodation.vue";
import done from "./views/Done.vue";
import map from "./views/Map.vue";
import package_deals from "./views/Package-deals.vue";
import arrangements from "@/views/Arrangements.vue";

Vue.use(Router);

const router = new Router({
    mode: store.state.settings.router_mode,
    routes: [
        {
            path: "/",
            props: {
                header: false,
            },
            // redirect: (to) => {
            //     return store.state.settings.view;
            // },
            beforeEnter: (to, from, next) => {
                next({ path: cc_settings.view ? cc_settings.view : store.state.settings.view });
            },
        },
        {
            path: "/search",
            name: "search",
            component: search,
        },
        {
            path: "/list",
            name: "list",
            component: list,
        },
        {
            path: "/book",
            name: "book",
            component: book,
        },
        {
            path: "/done",
            name: "done",
            component: done,
        },
        {
            path: "/map",
            name: "map",
            component: map,
        },
        {
            path: "/package-deals",
            name: "package-deals",
            component: package_deals,
        },
        {
            path: "/accommodation/:accommodation_id",
            name: "accommodation",
            component: accommodation,
        },
        {
            path: "/arrangements",
            name: "arrangements",
            component: arrangements,
        },
        {
            path: "*",
            redirect: "/",
        },
    ],
    // scrollBehavior(to, from, savedPosition) {
    //         return { x: 0, y: 0 };
    // },
});

router.beforeEach((from, to, next) => {
    if (to.path === "/") {
        next({ to: store.state.settings.view });
    }
    next();
});

router.afterEach((from, to, next) => {
    try {
        document.querySelector(".cc_app").scrollIntoView();
    } catch {
        //noting, used to supress console error
    }
});

export default router;
