// import Moment from "moment";
import { extendMoment } from "moment-range";
import class_pricecalculation from "@/api/price_calculation/class.price_calculation.js";

export const Price = {
    data() {
        return {
            calculation: {},
            foundPrice: false,
            loader: true,
            moment: extendMoment(this.$moment),
            // invalid_arrival: false,
            // invalid_departure: false,
        };
    },

    computed: {
        range() {
            return {
                start: this.moment(this.reservation.arrival).toDate(),
                end: this.moment(this.reservation.departure).toDate(),
            };
        },

        discountCard() {
            return this.$store.state.reservation.discount_card;
        },

        allow_booking() {
            // console.log(
            //     this.reservation.total > 0,
            //     this.reservation.persons <= this.accommodation.meta.persons_max,
            //     this.foundPrice,
            //     this.$helpers.day_is_valid(this.reservation.arrival, this.accommodation.meta.arrival_days),
            //     this.$helpers.day_is_valid(this.reservation.departure, this.accommodation.meta.departure_days)
            // );

            //

            return (
                this.reservation.total > 0 &&
                this.reservation.persons <= this.accommodation.meta.persons_max &&
                this.foundPrice &&
                this.$helpers.day_is_valid(this.reservation.arrival, this.accommodation.meta.arrival_days) &&
                this.$helpers.day_is_valid(this.reservation.departure, this.accommodation.meta.departure_days) &&
                this.dates_equal(this.reservation, this.$store.state.price_calculation)
            );
        },
    },

    watch: {
        range: function() {
            if (this.check_selection()) {
                if (this.$route.name === "list") {
                    this.calculate_prices();
                } else {
                    this.calculate_price();
                }
            }
        },

        accommodation() {
            if (this.$route.name === "accommodation" && !this.accommodation) {
                this.check_selection();
            }
        },

        discountCard() {
            if (this.$route.name === "list") {
                //Recently added, if calculation errors, remove this first.
                this.calculate_prices();
            }
        },
    },

    methods: {
        check_selection() {
            if (!this.duration_is_valid() || !this.reservation.arrival || !this.reservation.departure || !this.period_is_valid()) {
                this.foundPrice = false;
                this.loader = false;
                return false;
            } else {
                // this.loader = true;
                this.foundPrice = true;
                return true;
            }
        },

        calculate_prices() {
            this.loader = true;
            console.log(this.accommodation);
            if (this.reservation.arrival && this.reservation.departure && this.reservation.persons > 0) {
                let price_calculations = new class_pricecalculation();
                price_calculations.set_accommodation_id(this.accommodation.id);
                price_calculations.set_amount(this.reservation.age_tables);

                if (this.reservation.discount_card) {
                    price_calculations.set_discount_card(this.reservation.discount_card);
                }

                price_calculations.get_all().then((response) => {
                    this.calculation = response;
                    // this.calculation.total = this.fx.convert(this.calculation.total, { from: "EUR", to: "CZK" });

                    this.loader = false;
                });
            }
        },

        calculate_price() {
            this.loading.recalculate_price = true;

            if (this.reservation.arrival && this.reservation.departure && this.reservation.persons > 0) {
                let price_calculation = new class_pricecalculation();
                price_calculation.set_accommodation_id(this.accommodation.id);
                price_calculation.set_amount(this.reservation.age_tables);

                if (this.reservation.discount_card) {
                    price_calculation.set_discount_card(this.reservation.discount_card);
                }

                if (this.reservation.arrangement) {
                    price_calculation.set_arrangement_id(this.reservation.arrangement.id);
                }

                price_calculation.get();
            }
        },

        duration_is_valid() {
            /**
             * @TODO Expand with duration min
             */
            if (this.accommodation.meta.hasOwnProperty("duration_max") && this.accommodation.meta.duration_max) {
                let arrival = this.$moment(this.reservation.arrival);
                let departure = this.$moment(this.reservation.departure);

                let duration = departure.diff(arrival, "days");

                if (duration > this.accommodation.meta.duration_max) {
                    return false;
                }
            }

            return true;
        },

        period_is_valid() {
            let currentRoute = this.$route.name;
            if (currentRoute === "list" || currentRoute === "accommodation" || currentRoute === "book") {
                let valid = true;
                let arrival = this.moment(this.reservation.arrival);
                let departure = this.moment(this.reservation.departure);
                let dates_without_stock;
                dates_without_stock =
                    typeof this.accommodation.meta.dates_without_stock === "string" ? JSON.parse(this.accommodation.meta.dates_without_stock) : this.accommodation.meta.dates_without_stock;
                let range = this.moment().range(arrival, departure);

                if (dates_without_stock) {
                    dates_without_stock.forEach((date) => {
                        if (range.contains(date)) {
                            valid = false;
                        }
                    });
                }

                return valid;
            }
        },

        dates_equal(reservation, calculation) {
            if (calculation && calculation.hasOwnProperty("arrival") && calculation.hasOwnProperty("departure")) {
                if (reservation.arrival === calculation.arrival && reservation.departure === calculation.departure) {
                    return true;
                }

                return false;
            }
        },
    },
};
