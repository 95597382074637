import moment from "moment";
import { extendMoment } from "moment-range";

extendMoment(moment);

class helpers {
    
    get_api_endpoint(store, endpoint, params) {

        // return store.settings.api_path + "/" + endpoint + "?public_key=" + store.settings.public_key + "&lang=" + store.settings.language + "&admin_id=" + store.administration.id + "&channel_id=" + store.settings.channel_id + params;
        return "https://api.camping.care/v2" + "/" + endpoint + "?public_key=" + store.settings.public_key + "&lang=" + store.settings.language + "&admin_id=" + store.administration.id + "&channel_id=" + store.settings.channel_id + params;

    }

    get_old_api_endpoint(store, endpoint, params){
        return store.settings.api_path + "/" + endpoint + "?public_key=" + store.settings.public_key + "&lang=" + store.settings.language + "&admin_id=" + store.administration.id + "&channel_id=" + store.settings.channel_id + params;
    }

    // create_request(type, endpoint, params = null){
        

    // }

    get_money(price, state) {
        /**
         *
         * price_notation
         * int, eu, int_after, eu_after
         */

        // if (price >= 0) {
        let valuta = state.administration.meta.currency;

        if(typeof cc_settings.currency != 'undefined' && cc_settings.currency){
            valuta = cc_settings.currency ;
        }

        let price_notation = state.administration.meta.price_notation;
        let valutaSymbol = state.currency[valuta];
        let seperator = price_notation === "eu" || price_notation === "eu_after" ? "," : ".";

        if (price % 1 != 0) {
            price = price.toString();
            //Number with decimals
            let commaIndex = 0;

            if (price_notation == "eu" || price_notation == "eu_after") {
                price = price.replace(".", ",");
                commaIndex = price.indexOf(",") + 1;
            } else {
                // price = price.toString();
                commaIndex = price.indexOf(".") + 1;
            }

            let stringLength = price.length;

            /** Add 0 when there is one decimal */
            if (stringLength - commaIndex == 1) {
                price += "0";
            } else if (stringLength - commaIndex > 2) {
                let formatted = price.split(seperator)[1].substr(0, 2);
                /** Round prices if needed here **/

                price = price.split(seperator)[0] + seperator + formatted;
            }

            if (price_notation == "int" || price_notation == "eu") {
                //removed space
                return valutaSymbol + "" + price;
            }
            return price + " " + valutaSymbol;
        } else {
            //Number without decimals
            if (price_notation == "int" || price_notation == "eu") {
                return valutaSymbol + price + seperator + "00 ";
            }
            return price + seperator + "00 " + valutaSymbol;
        }
        // }
    }

    stripslashes(string) {
        return string.replace(/\\(.)/gm, "$1");
    }

    setToCorrectDays(days) {

        let correctDefaultDays = [];

        if (days) {

            let keys = Object.keys(days);

            keys.forEach((key) => {

                //foreach for looping over keys

                if (days[key] != null && Array.isArray(days[key])) {

                    let formattedDay = 0;

                    days[key].forEach((objectProp) => {
                        let correctExceptionDays = [];
                        //foreach for looping over object props data
                        if (key === "default") {
                            formattedDay = objectProp + 1;

                            if (formattedDay === 8) {
                                formattedDay = 1;
                            }

                            correctDefaultDays.push(formattedDay);
                        } else if (objectProp) {
                            objectProp.days.forEach((exceptionDay) => {
                                formattedDay = exceptionDay + 1;

                                if (formattedDay === 8) {
                                    formattedDay = 1;
                                }

                                correctExceptionDays.push(formattedDay);
                            });

                            objectProp.days = correctExceptionDays;
                        }
                    });
                } else {
                    return {};
                }

            });

            days.default = correctDefaultDays;

            return days;
        }
    }

    day_is_valid(day, dates) {

        if (typeof dates === "string") {
            dates = JSON.parse(dates);
        }

        day = moment(day).format("YYYY-MM-DD");

        let is_valid = false;
        let dayNumber = moment(day).day();

        if (dayNumber == 0) {
            dayNumber = 7;
        }

        let day_in_exception = false;

        let range = "";

        if (dates) {

            if (dates.exceptions == []) {
                dates.exceptions = null;
            }

            if (!dates.hasOwnProperty("exceptions") && dates.exceptions.length <= 0) {
                dates.exceptions = null;
            }

            if (dates.exceptions != null && Array.isArray(dates.exceptions)) {

                dates.exceptions.forEach((exception) => {

                    range = moment().range(exception.start, exception.end);

                    if (range.contains(moment(day))) {
                        day_in_exception = true;
                        if (exception.days.includes(dayNumber)) {
                            is_valid = true;
                        }
                    }

                });

            }

            if (!day_in_exception) {

                if (!dates.hasOwnProperty("default")) {
                    dates.default = [1, 2, 3, 4, 5, 6, 7];
                }

                if (dates.default && dates.default.includes(dayNumber)) {
                    is_valid = true;
                }
                
            }

            return is_valid;
        }
        return true;
    }
}

export default new helpers();
